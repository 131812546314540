import {
  // useLocation,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { useAuthContext } from 'src/auth/useAuthContext';
import * as Yup from 'yup';
import { useSnackbar } from 'src/components/snackbar';

// @mui
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // FormControl,
  Grid,
  // IconButton,
  // ListItemIcon,
  // Menu,
  // MenuItem,
  // Modal,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import axios, { setNavigateAndLogoutFunction } from 'src/utils/axios';
// import axios from 'src/utils/axios';
// import setNavigateAndLogoutFunction from 'src/utils/axios';
import NavSectionHorizontal from 'src/components/nav-section/horizontal';
import Iconify from 'src/components/iconify';
// import { HEADER } from 'src/config-global';
import {
  useEffect,
  //  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

interface FormValuesProps {
  Message: string;
  BrodcastType: string;
}

export default function MainLayout() {
  // const { pathname } = useLocation();
  const { user } = useAuthContext();
  // const isHome = false;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { logout } = useAuthContext();
  const CommentSchema = Yup.object().shape({
    Message: Yup.string().required('Message is required'),
    BrodcastType: Yup.string().required('Brodcast Type is required'),
  });
  const defaultValues = {
    Message: '',
    BrodcastType: 'all',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    // reset,
    handleSubmit,
    // setValue,
    // formState: { isSubmitting },
  } = methods;
  let NAV_ITEMS: any[] = [];
  let NavRightItems: any[] = [];
  const [openBrodcast, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseBrodcast = () => {
    setOpen(false);
  };
  if (user?.UserRole !== 'Assessor' && user?.UserRole !== 'Workflow') {
    NavRightItems = [
      {
        subheader: 'Profile',
        items: [
          {
            title: 'Profile',
            path: '#',
            icon: <Iconify icon="healthicons:ui-user-profile" />,
            children: [
              {
                title: 'View Profile',
                path: 'profile',
                icon: <Iconify icon="healthicons:ui-user-profile" />,
              },
              {
                title: 'Portal Manual',
                path: 'manual',
                icon: <Iconify icon="streamline:manual-book-solid" />,
              },
              // { title: 'Broadcast', path: '#', icon: <Iconify icon="ph:broadcast-bold" onClick={handleOpen} /> },
              {
                title: '',
                path: '#',
                icon: (
                  <Button
                    color="inherit"
                    onClick={handleOpen}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHoverLeave}
                    sx={{
                      backgroundColor: isHovered ? 'transparent' : 'transparent',
                      '&:hover': {
                        backgroundColor: isHovered ? 'transparent' : 'transparent',
                      },
                    }}
                  >
                    <Grid item>
                      <Iconify
                        icon="ph:broadcast-bold"
                        style={{
                          color: isHovered ? 'white' : '#919eab',
                          fontSize: '14.5px',
                          marginRight: '0px',
                          marginLeft: '37px',
                          marginTop: '7px',
                        }}
                      />
                    </Grid>
                    <Typography
                      style={{
                        color: isHovered ? 'white' : '#919eab',
                        fontSize: '14.1px',
                        marginLeft: '9px',
                      }}
                    >
                      Broadcast
                    </Typography>
                    <Grid />
                  </Button>
                ),
              },
              // { title: 'Help', path: '#', icon: <Iconify icon="ic:baseline-help" /> },
            ],
          },
        ],
      },
    ];
  }

  if (user?.UserRole === 'Assessor') {
    NAV_ITEMS = [
      {
        subheader: 'Energy Advance',
        items: [
          {
            title: 'Dashboard',
            path: `/viewassessor?id=${user?.ID}&edit=true&section=overview`,
            icon: <Iconify icon="carbon:dashboard" />,
          },
          {
            title: 'Jobs',
            path: '/jobs',
            icon: <Iconify icon="eos-icons:job" />,
          },
          {
            title: 'Report',
            path: '/reports',
            icon: <Iconify icon="carbon:report" />,
          },
          {
            title: 'InfoBot',
            path: '/chatBot',
            icon: <Iconify icon="fluent-mdl2:chat-bot" />,
          },

          // {
          //   title: 'Teams',
          //   path: '#',
          //   icon: <Iconify icon="fluent:people-team-48-filled" />,
          //   children: [{ title: 'Assessor Team ', path: '/assessorlist' }],
          // },
        ],
      },
    ];
    NavRightItems = [
      {
        subheader: 'Profile',
        items: [
          {
            title: 'Profile',
            path: '#',
            icon: <Iconify icon="healthicons:ui-user-profile" />,
            children: [
              {
                title: 'View Profile',
                path: 'profile',
                icon: <Iconify icon="healthicons:ui-user-profile" />,
              },
              {
                title: 'Portal Manual',
                path: 'manual',
                icon: <Iconify icon="streamline:manual-book-solid" />,
              },
            ],
          },
        ],
      },
    ];
  } else if (user?.UserRole === 'Management' || user?.UserRole === 'Admin') {
    NAV_ITEMS = [
      {
        subheader: 'Energy Advance',
        items: [
          {
            title: 'Dashboard',
            path: '/dashboard',
            icon: <Iconify icon="carbon:dashboard" />,
          },
          {
            title: 'Requestors',
            path: '/requestors',
            icon: <Iconify icon="fluent:branch-request-20-filled" />,
          },
          {
            title: 'Jobs',
            path: '/jobs',
            icon: <Iconify icon="eos-icons:job" />,
          },
          // {
          //   title: 'Basix Refund Certificate',
          //   path: '/refund jobs',
          //   icon: <Iconify icon="eos-icons:job" />,
          // },
          {
            title: 'Teams',
            path: '#',
            icon: <Iconify icon="fluent:people-team-48-filled" />,
            children: [
              { title: 'Management Team ', path: '/managementUserList' },
              { title: 'Workflow Team', path: '/workflowUserList' },
              { title: 'Assessor Team ', path: '/assessorlist' },
            ],
          },
          {
            title: 'Report',
            path: '/reports',
            icon: <Iconify icon="carbon:report" />,
          },
          {
            title: 'InfoBot',
            path: '/chatBot',
            icon: <Iconify icon="fluent-mdl2:chat-bot" />,
            // icon: <img src="https://a0.anyrgb.com/pngimg/1394/800/robot-icon-industrial-robot-chatbot-droid-internet-bot-zip-small-robot-android-electronics.png" alt="ChatBot Icon" />,
          },
        ],
      },
    ];
  } else if (user?.UserRole === 'Workflow') {
    NAV_ITEMS = [
      {
        subheader: 'Energy Advance',
        items: [
          {
            title: 'Dashboard',
            path: '/dashboard',
            icon: <Iconify icon="carbon:dashboard" />,
          },
          {
            title: 'Requestors',
            path: '/requestors',
            icon: <Iconify icon="fluent:branch-request-20-filled" />,
          },
          {
            title: 'Jobs',
            path: '/jobs',
            icon: <Iconify icon="eos-icons:job" />,
          },
          // {
          //   title: 'Basix Refund Certificate',
          //   path: '/refund jobs',
          //   icon: <Iconify icon="eos-icons:job" />,
          // },
          {
            title: 'Teams',
            path: '#',
            icon: <Iconify icon="fluent:people-team-48-filled" />,
            children: [
              { title: 'Workflow Team', path: '/workflowUserList' },
              { title: 'Assessor Team ', path: '/assessorlist' },
            ],
          },
          {
            title: 'Report',
            path: '/reports',
            icon: <Iconify icon="carbon:report" />,
          },
          {
            title: 'InfoBot',
            path: '/chatBot',
            icon: <Iconify icon="fluent-mdl2:chat-bot" />,
            // icon: <img src="https://a0.anyrgb.com/pngimg/1394/800/robot-icon-industrial-robot-chatbot-droid-internet-bot-zip-small-robot-android-electronics.png" alt="ChatBot Icon" />,
          },
        ],
      },
    ];
    NavRightItems = [
      {
        subheader: 'Profile',
        items: [
          {
            title: 'Profile',
            path: '#',
            icon: <Iconify icon="healthicons:ui-user-profile" />,
            children: [
              {
                title: 'View Profile',
                path: 'profile',
                icon: <Iconify icon="healthicons:ui-user-profile" />,
              },
              {
                title: 'Portal Manual',
                path: 'manual',
                icon: <Iconify icon="streamline:manual-book-solid" />,
              },
            ],
          },
        ],
      },
    ];
  }

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (dat: FormValuesProps) => {
    try {
      await CommentSchema.validate(dat, { abortEarly: false });
      console.info(dat);
      setIsLoading(true);
      axios
        .post(`/core/brodcastMessage`, dat)
        .then((response) => {
          enqueueSnackbar(response.data);
          setIsLoading(false);
          setOpen(false);
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(true);
    }
  };
  // This is for auto logout
  const navigate = useNavigate();
  useEffect(() => {
    // console.log('Main layout mounted');
    setNavigateAndLogoutFunction(navigate, logout);
  }, [logout, navigate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Dialog fullWidth maxWidth="md" open={openBrodcast} onClose={handleCloseBrodcast}>
        <DialogTitle> Broadcast Message</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 4 }}>
            {/* Add your text input field here */}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} display="flow" xs={12} md={12}>
                <RHFTextField name="Message" label="Message" sx={{ width: '100%', mb: 2 }} />
                <RHFRadioGroup
                  row
                  name="BrodcastType"
                  label="Broadcast Type"
                  spacing={4}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'Workflow', label: 'Workflow Team' },
                    { value: 'Assessor', label: 'Assessor Team' },
                    { value: 'Management', label: 'Management Team' },
                  ]}
                  disabled={false}
                />
              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ marginRight: 2 }}
                >
                  Send
                </LoadingButton>
                <Button variant="contained" onClick={handleCloseBrodcast}>
                  Cancel
                </Button>
              </Box>
            </FormProvider>
          </Box>
        </DialogContent>
      </Dialog>
      <Stack spacing={2} sx={{ mb: 0 }}>
        <AppBar
          position="static"
          component="nav"
          color="default"
          sx={{
            boxShadow: 0,
            width: '100',
            position: 'fixed',
            zIndex: 1,
            // top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }}
        >
          <Toolbar>
            <img
              src={!isDarkMode ? './logo.png' : './logodark.png'}
              alt="Logo"
              style={{ width: '50px', height: '50px' }}
            />
            <Typography typography="body1" sx={{ marginLeft: '15px' }}>
              {' '}
              {`${user?.UserFname}  ${user?.UserLname}`}
            </Typography>
            <NavSectionHorizontal data={NAV_ITEMS} />
            <NotificationsPopover />
            <NavSectionHorizontal data={NavRightItems} sx={{ margin: 0 }} />
            <Button color="primary" onClick={() => logout()}>
              <Iconify icon="solar:logout-3-bold" />
              <Typography variant="body1">Logout</Typography>
            </Button>
          </Toolbar>
        </AppBar>
      </Stack>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginTop: 8,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}


