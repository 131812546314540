import { useCallback, useEffect, useState } from 'react';
// import { axios, axiosInstance } from 'src/utils/axios';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  styled,
  Checkbox,
  Box,
  IconButton,
} from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
// import label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
// components
import Editor from 'src/components/editor';
import {
  // useLocation,
  useNavigate,
} from 'react-router';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import SelectTemplate from './SelectTemplate';
// import Iconify from '../../components/iconify';
// import { Upload } from '../../components/upload';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  getComments: VoidFunction;
  getVersions: VoidFunction;
  getEmails: VoidFunction;
  job: any;
  attachments: any;
  initial_email_content: any;
}

type FormValuesProps = {
  EmailSubject: string;
  SendEmail: string;
  AddressTo: string;
  CCAddress: string;
  JobRecordID?: string;
};

interface EmailInfo {
  EmailRecordID: number;
  EmailMsgID: number;
  EmailFolderID: number;
  EmailSubject: string;
  EmailSummary: string;
  EmailHasAttach: number;
  EmailFlagID: string;
  EmailSender: string;
  EmailToAddress: string;
  EmailCCAddress: string;
  EmailLabelID: number;
  EmailReceivedTime: number;
  EmailStatus: number;
  EmailStatus2: number;
  ThreadCount?: number | null;
  ThreadID?: number | null;
  EmailIsJob: string;
  EmailCreatedAt: string;
  EmailIsProcessed: string;
  EmailProcessedAt: string;
  EmailJSON: any;
}

export default function JobCompleteDialog({
  open,
  onClose,
  getComments,
  getVersions,
  getEmails,
  job,
  attachments,
  initial_email_content,
}: Props) {
  const { user } = useAuthContext();
  // const [selectedDocumentsForConfirmation, setSelectedDocumentsForConfirmation] = useState<string[]>([]);
  const [confirmationContent, setConfirmationContent] = useState<React.ReactNode>(null);
  // const [data, setData] = useState<EmailInfo | null>(null);
  const [data, setData] = useState(false);
  const [emailData, setEmailData] = useState<EmailInfo | null>(null);
  const [formData, setFormData] = useState(new FormData());
  const [editor, setEditor] = useState('');
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const [parkJobComment, setParkJobCommet] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const latest_email = initial_email_content[initial_email_content.length - 1]?.EmailSummary;
  const CommentSchema = Yup.object().shape({
    EmailSubject: Yup.string().required('Email Subject is required'),
    SendEmail: Yup.boolean().required('Send Email Option is required'),
    AddressTo: Yup.string().required('To Address is required'),
    CCAddress: Yup.string(),
  });
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues: {
      EmailSubject: '',
      SendEmail: 'true',
      AddressTo: '',
      CCAddress: '',
    },
  });
  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;

  const fetchEmailInfoById = useCallback(async () => {
    if (!job?.EmailRecordID) return;
    try {
      const response = await axios.get(`/core/getEmailInfoById?Id=${job?.EmailRecordID}`);
      const email_data = response.data;

      // console.log('Fetched email data:', email_data);
      setEmailData(email_data as EmailInfo);

      if (!email_data?.EmailToAddress && !email_data?.EmailSender) {
        setValue('AddressTo', '');
      } else if (!email_data?.EmailToAddress) {
        setValue('AddressTo', email_data?.EmailSender);
      } else {
        setValue('AddressTo', `${email_data?.EmailToAddress}, ${email_data?.EmailSender}`);
      }
      setValue('EmailSubject', email_data?.EmailSubject);
      setValue('CCAddress', email_data.EmailCCAddress);
      setEditor(latest_email);
    } catch (error) {
      console.error('Error fetching getEmailInfoById:', error);
    }
  }, [job?.EmailRecordID, latest_email, setValue]);

  const getDraftMessageJobComp = useCallback(
    async (jobRecordId: any, userId: string, action_value: string) => {
      try {
        const response = await axios.get(`/core/getMessages`, {
          params: {
            JobRecordID: jobRecordId,
            UserID: userId,
            Action: action_value,
          },
        });

        const draftMessageJCom = response.data;
        if (draftMessageJCom) {
          setValue('EmailSubject', draftMessageJCom.EmailSubject);
          setValue('AddressTo', draftMessageJCom.AddressTo);
          setValue('CCAddress', draftMessageJCom.CCAddress);
          setEditor(draftMessageJCom.draft_message);
        }
        return draftMessageJCom;
      } catch (error: any) {
        if (error.response) {
          if (error.response.status !== 404) {
            enqueueSnackbar(error.response.data?.error || 'Failed to retrieve draft message', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('No draft message found for the given parameters (404).', {
              variant: 'error',
            });
          }
        }
        return null;
      }
    },
    [enqueueSnackbar, setValue]
  );

  // useEffect(() => {
  //   if (job?.JobRecordID && user?.ID) {
  //     getDraftMessageJobComp(job.JobRecordID, user.ID, 'Send Job Back To Client');
  //   }
  // }, [getDraftMessageJobComp, job?.JobRecordID, user?.ID]);

  const onValueChanges = (value: any) => {
    setEditor(value);
  };

  const [files, setFiles] = useState<(File | string)[]>([]);

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);
  const values = watch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (job?.JobRecordID && user?.ID) {
        try {
          const draft_response = await getDraftMessageJobComp(
            job.JobRecordID,
            user.ID,
            'Send Job Back To Client'
          );
          if (!draft_response || Object.keys(draft_response).length === 0) {
            fetchEmailInfoById();
          }
        } catch (error) {
          console.error('Error fetching draft message:', error);
        }
      } else {
        fetchEmailInfoById();
      }
    };

    fetchData();
  }, [fetchEmailInfoById, getDraftMessageJobComp, job?.JobRecordID, user?.ID]);

  // useEffect(() => {
  //   console.log('Job having email record id');
  //   if (job?.EmailRecordID) {
  //     console.log('Job having email record id');
  //     axios
  //       .get(`/core/getEmailInfoById?Id=${job?.EmailRecordID}`)
  //       .then((response) => {
  //         console.log('Response for email info for job = ', response.data);
  //         setData(true);
  //         setValue('AddressTo', `${response.data.EmailToAddress} , ${response.data.EmailSender}`);
  //         if (response.data.EmailToAddress === '' && response.data.EmailSender === '') {
  //           setValue('AddressTo', ``);
  //         } else if (response.data.EmailToAddress === '') {
  //           setValue('AddressTo', `${response.data.EmailSender}`);
  //         } else {
  //           setValue('AddressTo', `${response.data.EmailToAddress} , ${response.data.EmailSender}`);
  //         }
  //         setValue('CCAddress', response.data.EmailCCAddress);
  //         setValue('EmailSubject', response.data.EmailSubject);
  //         setEditor(latest_email);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching getEmailInfoById:', error);
  //       });
  //   }
  // }, [job?.EmailRecordID, job?.JobRecordID, setValue, latest_email, data]);

  // const handleDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const newFiles = acceptedFiles.map((file) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     );

  //     setFiles([...files, ...newFiles]);
  //   },
  //   [files]
  // );

  function formatFileSize(bytes: any) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
  }

  const StyledRoot = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      zIndex: 9,
      position: 'relative',
      // boxShadow: theme.customShadows.z24,
      '& #mailActions': { opacity: 1 },
    },
  }));

  const handleEdit = () => {
    setIsReviewMode(false);
    setConfirmSend(false);
  };

  const handleConfirm = async () => {
    try {
      setConfirmationContent(null);
      if (editor !== '') {
        setIsLoading(true);
        axios
          .post(`/core/completeJob`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            enqueueSnackbar(response.data);
            setEditor('');
            if (job?.JobRecordID && user?.ID) {
              deleteDraftMessage(job?.JobRecordID, user?.ID, 'Send Job Back To Client');
            }
            // setValue('AddressTo', '');
            // setValue('CCAddress', '');
            getEmails();
            // onClose()
            closeFunction();
            navigate('/jobs');
          })
          .catch((error) => {
            setIsLoading(false);
            enqueueSnackbar(error.error, { variant: 'error' });
          });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      console.error('Error uploading files:', error);
    }
  };

  const handleConfirmCancel = async () => {
    try {
      setFiles([]);
      setConfirmationContent(null);
    } catch (error) {
      setTimeout(() => { }, 1000);
      enqueueSnackbar(error, { variant: 'error' });
      console.error('Error uploading files:', error);
    }
  };
  const validateForm = (dat: FormValuesProps, selectedAttachments?: any[]) => {
    const errors: string[] = [];
    const editorContent = editor?.replace(/<[^>]*>/g, '').trim() || '';

    console.log('Editor validation check:', { original: editor, cleaned: editorContent, length: editorContent.length });

    if (!editorContent) {
      errors.push('Email Content Is Required');
    }
    if (!dat.AddressTo) {
      errors.push('To Address Is Required');
    }
    if (!dat.EmailSubject) {
      errors.push('Email Subject Is Required');
    }
    if (dat.SendEmail === 'true' && (!selectedAttachments || selectedAttachments.length < 1)) {
      errors.push(' Minimum 1 attachment to be selected');
    }


    return errors;
  };


  const onSubmit = async (dat: FormValuesProps) => {
    try {
      if (!isReviewMode) {
        const errors = validateForm(dat, selectedAttachments);
        if (errors.length > 0) {
          errors.forEach(error => enqueueSnackbar(error, { variant: 'error' }));
          return;
        }
        setIsReviewMode(true);
        return;
      }

      if (values.SendEmail === 'true') {
        await handleSendConfirmation(); // Send email
      }

      formData.forEach((value, key) => {
        formData.delete(key);
      });
      setFormData(new FormData());

      const newFormData = new FormData();
      const trimmedEditor = editor?.trim();
      const updatedEditor = trimmedEditor
        ?.replace(/<p\b([^>]*)>/gi, '<div$1>') // Update content format
        .replace(/<\/p>/gi, '</div>');

      // Append data to formData
      if (values.SendEmail === 'true') {
        formData.append('AttachmentId', JSON.stringify(selectedAttachments));
        formData.append('EmailSubject', dat.EmailSubject);
        formData.append('EmailContent', updatedEditor);
        formData.append('AddressTo', dat.AddressTo);
        formData.append('CCAddress', dat.CCAddress);
      }

      // Additional data to be sent
      formData.append('JobRecordID', job.JobRecordID);
      formData.append('SendEmail', String(dat.SendEmail));
      formData.append('JobEmailID', job.JobEmailMsgID);
      formData.append('EntryBy', user?.ID);
    } catch (error) {
      console.error(error);
    }
  };


  // const onSubmit = async (dat: FormValuesProps) => {
  //   try {
  //     if (!isReviewMode) {
  //       setIsReviewMode(true);
  //       return;
  //     }
  //     if (values.SendEmail === 'true') {
  //       await CommentSchema.validate(dat, { abortEarly: false });

  //       if (editor === '') {
  //         enqueueSnackbar('Email Content is Required', { variant: 'error' });
  //         return;
  //       }

  //       if (editor !== '') {
  //         await handleSendConfirmation() // Send email
  //       }
  //     }

  //     formData.forEach((value, key) => {
  //       formData.delete(key);
  //     });
  //     setFormData(new FormData());

  //     const newFormData = new FormData();
  //     const trimmedEditor = editor?.trim();
  //     const updatedEditor = trimmedEditor
  //       ?.replace(/<p\b([^>]*)>/gi, '<div$1>')  // Update content format
  //       .replace(/<\/p>/gi, '</div>');

  //     // Append data to formData
  //     if (values.SendEmail === 'true') {
  //       formData.append('AttachmentId', JSON.stringify(selectedAttachments));
  //       formData.append(`EmailSubject`, dat.EmailSubject);
  //       formData.append(`EmailContent`, updatedEditor);
  //       formData.append(`AddressTo`, dat.AddressTo);
  //       formData.append(`CCAddress`, dat.CCAddress);
  //     }

  //     // Additional data to be sent
  //     formData.append(`JobRecordID`, job.JobRecordID);
  //     formData.append(`SendEmail`, String(dat.SendEmail));
  //     formData.append(`JobEmailID`, job.JobEmailMsgID);
  //     formData.append(`EntryBy`, user?.ID);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const postComment = (comment: string) => {
    const payload = {
      Comment: comment,
      JobRecordID: job.JobRecordID,
      Sequence: comment?.length,
    };
    if (comment !== '') {
      axios
        .post(`/core/postNewComment?Id=${user?.ID}`, payload)
        .then((_response) => {
          // if (comment) {
          //   comment = '';
          // }
          setParkJobCommet('');
          getComments();
          onClose();
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } else {
      enqueueSnackbar('Enter Something To Post', { variant: 'warning' });
    }
  };

  const markAsComplete = () => {
    try {
      const formData1 = new FormData();
      // setIsLoading(true)
      formData1.append(`JobRecordID`, job.JobRecordID);
      formData1.append(`SendEmail`, values.SendEmail);
      formData1.append(`JobEmailID`, job.JobEmailMsgID);
      formData1.append(`EntryBy`, user?.ID);
      if (parkJobComment !== '') {
        axios
          .post(`/core/completeJob`, formData1, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            enqueueSnackbar(response.data);
            postComment(parkJobComment);
            setEditor('');
            setValue('AddressTo', '');
            setValue('CCAddress', '');
            onClose();
            getComments();
            getVersions();
            getEmails();
            navigate('/jobs');
            // setIsLoading(true)
          })
          .catch((error) => {
            enqueueSnackbar(error);
            // setIsLoading(true)
          });
      } else {
        enqueueSnackbar('Please Enter Comment', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      // setIsLoading(true)
    }
  };

  const closeFunction = () => {
    try {
      setSelectedAttachments([]);
      setIsLoading(false);
      setData(false);
      onClose();
    } catch (error) {
      console.error(error);
      // setIsLoading(true)
    }
  };

  // const handleRemoveFile = (inputFile: File | string) => {
  //   const filtered = files.filter((file) => file !== inputFile);
  //   setFiles(filtered);
  // };

  // const handleRemoveAllFiles = () => {
  //   setFiles([]);
  // };

  const [selectedAttachments, setSelectedAttachments] = useState<any>([]);

  const handleCheckboxChange = (jobAttachID: any) => {
    const isSelected = selectedAttachments.includes(jobAttachID);
    if (isSelected) {
      setSelectedAttachments((prevSelected: any) =>
        prevSelected.filter((id: any) => id !== jobAttachID)
      );
    } else {
      setSelectedAttachments((prevSelected: any) => [...prevSelected, jobAttachID]);
    }
  };

  const handleSendConfirmation = async () => {
    if (selectedAttachments.length > 0) {
      const allowedExtensions = ['.xlsx', '.csv', '.frate', '.xls', '.xlrd'];
      const selectedFileNames = attachments
        .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        //     .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        .map((item: any) => item.JobAttachName)
        .join('<br />');
      const IncorrectFilenames = attachments
        .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        .filter((item: any) => {
          const fileExtension = item.JobAttachLoc.slice(item.JobAttachLoc.lastIndexOf('.'));
          return allowedExtensions.includes(fileExtension);
        })
        //     .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        .map((item: any) => item.JobAttachName)
        .join('<br />');
      if (selectedFileNames) {
        let confirmationMessage = `Kindly confirm to Proceed ?`;
        if (IncorrectFilenames) {
          confirmationMessage = `You are sending Excel files or Frate Files to Requestor. Kindly confirm? <br> ${IncorrectFilenames}`;
        }
        setConfirmationContent(
          <Dialog open onClose={() => setConfirmationContent(null)}>
            <DialogTitle>Confirmation</DialogTitle>
            {/* <DialogContent>{confirmationMessage}</DialogContent> */}
            <DialogContent>
              <div dangerouslySetInnerHTML={{ __html: confirmationMessage }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm} color="primary">
                Confirm
              </Button>
              <Button onClick={handleConfirmCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        );
      } else {
        enqueueSnackbar('Selected files not found.', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Please select files to send.', { variant: 'error' });
    }
  };
  const saveAsDraft = async () => {
    try {
      const formData1 = new FormData();
      formData1.append('JobRecordID', job.JobRecordID);
      formData1.append('EmailSubject', values.EmailSubject || '');
      formData1.append('DraftMessage', editor);
      formData1.append('AddressTo', values.AddressTo || '');
      formData1.append('CCAddress', values.CCAddress || '');
      formData1.append('UserID', user?.ID);
      formData1.append('Action', 'Send Job Back To Client');
      console.log('Data of draft to save = ', formData1);
      const response = await axios.post('/core/draftMessage', formData1, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      enqueueSnackbar(response.data.message || 'Draft saved successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error saving draft:', error);
      enqueueSnackbar('Failed to save draft. Please try again.', { variant: 'error' });
    }
  };

  const deleteDraftMessage = useCallback(
    async (jobRecordId: any, userId: string, deleteAction: string) => {
      try {
        setIsLoading(true);

        // Make DELETE request to the API
        const response = await axios.delete(`/core/deleteDraftMessage`, {
          params: {
            JobRecordID: jobRecordId,
            UserID: userId,
            Action: deleteAction,
          },
        });
        console.log("response data of delete draft", response)
        // Check if the deletion was successful and a record was found to delete
        if (response.status === 200) {
          //          enqueueSnackbar('Draft message deleted successfully!', { variant: 'success' });
          console.log("Draft message deleted successfully!")
          // Clear draft-related states after deletion
          // setEmailSubject1('');
          // setEditor('');
          // setToAddress1('');
          // setCCAddress1('');
          // setEmailInput('');
        } else {
          //          enqueueSnackbar('Error in delete draft', { variant: 'error' });
          console.log("Error in delete draft")
        }
        return response.data;
      } catch (error) {
        // Show error message if deletion fails
        if (error.response && error.response.status !== 404) {
          // enqueueSnackbar(error.response?.data?.error || 'Failed to delete draft message', {
          //   variant: 'error',
          // });
          console.log("Failed to delete draft message")
        } else {
          // enqueueSnackbar('No draft message found to delete for the given parameters.', {
          //   variant: 'warning',
          // });
          console.log("No draft message found to delete for the given parameters.")
        }
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    []
  );
  //  setEditor(latest_email);

  const [templat, setTemplat] = useState(false);

  const openTemplateSelector = () => {
    setTemplat(true);
  };

  const [showTemplates, setShowTemplates] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);

  const handleEditorKeyDown = useCallback(async (event: any) => {
    console.log('Key pressed:', event.key);
    if (event.key === '/') {
      try {
        console.log('Fetching templates...');
        const response = await axios.get('/core/getAllWorkflowTemplates');
        console.log('Templates response:', response.data);
        setTemplates(response.data);
        setShowTemplates(true);
      } catch (error) {
        console.error('Error fetching templates:', error);
        enqueueSnackbar('Failed to load templates', { variant: 'error' });
      }
    } else if (event.key === 'Escape') {
      handleCloseDropdown();
      setShowTemplates(false);
    }
  }, [enqueueSnackbar]);

  const handleCloseDropdown = () => {
    setEditor((prev: string) => {
      if (prev.endsWith('/')) {
        return prev.slice(0, -1);
      }
      return prev;
    });
    setShowTemplates(false);
  };

  const handleTemplateSelect = async (template: any) => {
    try {
      // Call the useTemplate API
      const requestBody = {
        jobId: job?.JobRecordID,
        templateText: template.TemplateText,
      };

      const response = await axios.put('/core/useTemplate', requestBody);

      setEditor((prev: any)=> 
        `${prev} ${response?.data?.result || template.TemplateText}`
      );
      setShowTemplates(false);
      // enqueueSnackbar('Template applied successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error applying template:', error);
      enqueueSnackbar('Failed to apply template', { variant: 'error' });
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      {/* <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> Mark as Complete </DialogTitle> */}
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {isReviewMode ? 'Preview' : 'Mark as Complete'}</DialogTitle>

      <SelectTemplate
        open={templat}
        onClose={() => {
          setTemplat(false);
        }}
        setEditor={onValueChanges}
        job_data={job}
      />

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            <RHFRadioGroup
              row
              name="SendEmail"
              label=""
              spacing={4}
              options={[
                {
                  value: true,
                  label: 'Mark as Complete (Send Job back to the client) - with Send Email',
                },
                { value: false, label: 'Park job for rechecking' },
              ]}
              // disabled={false}
              disabled={isReviewMode}
            />
            {values.SendEmail === 'true' && (
              <>
                <RHFTextField name="AddressTo" label="Address To" sx={{ width: '100%' }} disabled={isReviewMode} />
                <RHFTextField name="CCAddress" label="CC Address" sx={{ width: '100%' }} disabled={isReviewMode} />
                <RHFTextField name="EmailSubject" label="Email Subject" sx={{ width: '100%' }} disabled={isReviewMode} />
                {/* <Editor value={editor} onChange={onValueChanges} readOnly={isReviewMode} onchan={setEditor} /> */}

                {!isReviewMode && (
                  <Button
                    variant="text"
                    onClick={() => {
                      openTemplateSelector();
                    }}
                    disabled={isReviewMode}
                  >
                    {' '}
                    Select Template
                  </Button>
                )}

                <div style={{ pointerEvents: isReviewMode ? 'none' : 'auto' }}>
                  <Stack spacing={2} sx={{ position: 'relative' }}>
                    <Editor
                      value={editor}
                      onChange={onValueChanges}
                      onchan={setEditor}
                      onKeyDown={handleEditorKeyDown}
                      readOnly={isReviewMode}
                      isReviewMode={isReviewMode}
                      style={{
                        color: isReviewMode ? '#777' : '',
                        pointerEvents: isReviewMode ? 'none' : 'auto',
                      }}
                    />

                    {showTemplates && !isReviewMode && (
                      <Stack
                        className="templates-dropdown"
                        sx={{
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          right: 0,
                          zIndex: 1000,
                          bgcolor: '#fff',
                          color: '#000',
                          boxShadow: 3,
                          borderRadius: 1,
                          p: 2,
                          maxHeight: 300,
                          overflow: 'auto',
                          mt: 1,
                          border: '1px solid',
                          borderColor: 'divider',
                        }}
                      >
                        {/* Close Button */}
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton
        onClick={handleCloseDropdown}
        size="large"
        sx={{ mb: 1 }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
                        {templates.length > 0 ? (
                          templates.map((template) => (
                            <Button
                              key={template.ID}
                              onClick={() => handleTemplateSelect(template)}
                              sx={{
                                justifyContent: 'flex-start',
                                py: 1,
                                px: 2,
                                width: '100%',
                                textAlign: 'left',
                                color: 'inherit',
                                '&:hover': {
                                  bgcolor: 'rgba(0, 0, 0, 0.04)'
                                }
                              }}
                            >
                              <Stack alignItems="flex-start" width="100%">
                                <Typography
                                  variant="subtitle2"
                                  sx={{ color: 'inherit' }}
                                >
                                  {template.TemplateName}
                                </Typography>
                                <Typography
                                  variant="caption"
                                // sx={{ color: 'grey.600' }}
                                >
                                  Category: {template.Category}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: 'grey.600',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '100%'
                                  }}
                                >
                                  {template.TemplateText?.replace(/(<([^>]+)>)/gi, '').substring(0, 100)}...
                                </Typography>
                              </Stack>
                            </Button>
                          ))
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{ p: 2 }}
                          >
                            No templates found
                          </Typography>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </div>
                {!isReviewMode && (
                  <Typography variant="subtitle2" noWrap>
                    Select attachments to Send Email to Requestor/Builder Note : Minimum 1 attachment
                    to be selected
                  </Typography>
                )}
                {attachments &&
                  attachments.map((list: any, key: any) => (
                    <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                      <Checkbox
                        checked={selectedAttachments.includes(list.JobAttachLoc)}
                        onChange={() => handleCheckboxChange(list.JobAttachLoc)}
                        disabled={isReviewMode}
                      />
                      <Stack
                        key={key}
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        sx={{
                          my: 1,
                          px: 1,
                          py: 0.75,
                        }}
                      >
                        <FileThumbnail file={list.JobAttachExtension} />

                        <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                          <Typography variant="subtitle2" noWrap>
                            {list.JobAttachName}
                          </Typography>
                          <Typography variant="subtitle2" noWrap>
                            {formatFileSize(list.JobAttachSize)}
                          </Typography>
                        </Stack>
                      </Stack>
                    </StyledRoot>
                  ))}
              </>
            )}

            {values.SendEmail === 'false' && (
              <RHFTextField
                name="ParkJobComment"
                label="Write Comment"
                onChange={(event) => setParkJobCommet(event.target.value)}
                multiline
                rows={4}
                sx={{ width: '100%' }}
              />
            )}
          </Stack>
        </DialogContent>

        {/* <DialogActions>
          {values.SendEmail === 'true' && (
            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading}
              onClick={saveAsDraft}
            >
              Save As Draft
            </Button>
          )}

          {values.SendEmail === 'true' && (
            <LoadingButton
              variant="contained"
              loading={isLoading}
              type="submit"
              // disabled={selectedAttachments.length < 1}
            >
              Send Mail
            </LoadingButton> 
          )}
          {values.SendEmail !== 'true' && (
            <LoadingButton variant="contained" loading={isLoading} onClick={markAsComplete}>
              Save
            </LoadingButton>
          )}
          <Button
            variant="outlined"
            color="secondary"
            // onClick={onClose}
            onClick={closeFunction}
            disabled={isLoading}
          >
            Cancel
          </Button>

          {user?.UserRole !== 'Assessor' && confirmationContent}
        </DialogActions> */}

        <DialogActions>
          {values.SendEmail === 'true' && !isReviewMode && (
            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading}
              onClick={saveAsDraft}
            >
              Save As Draft
            </Button>
          )}
          {values.SendEmail === 'true' && !isReviewMode && (
            <LoadingButton
              variant="contained"
              loading={isLoading}
              // disabled={selectedAttachments.length < 1}
              onClick={() => onSubmit(values)}
            >
              Review & Send Mail
            </LoadingButton>
          )}

          {values.SendEmail === 'true' && isReviewMode && (
            <>
              <LoadingButton
                variant="contained"
                loading={isLoading}
                onClick={() => onSubmit(values)}
              >
                Send Mail
              </LoadingButton>
              <Button variant="outlined" disabled={isLoading} onClick={() => setIsReviewMode(false)}>
                Back
              </Button>
            </>
          )}

          {values.SendEmail !== 'true' && (
            <LoadingButton variant="contained" loading={isLoading} onClick={markAsComplete}>
              Save
            </LoadingButton>
          )}
          {values.SendEmail === 'true' && !isReviewMode && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={closeFunction}
              disabled={isLoading}
            >
              Cancel
            </Button>
          )}
          {user?.UserRole !== 'Assessor' && confirmationContent}
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
