import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import axios from 'src/utils/axios';
import { useAuthContext } from 'src/auth/useAuthContext';
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import _mock, { randomInArray } from 'src/_mock';
import { useLocation, useNavigate } from 'react-router';
import DataGridAssessor from './DataGridCustom';
// import ViewAssessor from './View Assessor/ViewAssessor';

// type skillSetProps = {
//   id: any;
//   basix: string;
//   natHers: string;
//   varification: string;
//   urgentJobs: string;
//   bess: string;
//   dts: string;
// }[];

export default function AccessorList() {
  const location = useLocation();
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const { skillsetData } = location.state || [];
  // const { skillsetData } = location.state ?? { skillsetData: [] };
  // const skillsetData = filteredSkillsetData.length > 0 ? filteredSkillsetData[0] : undefined;
  const [isVisible, setIsVisible] = useState(false);
  const [showActiveOnly, setShowActiveOnly] = useState<boolean>(true);
  const [assessor, setAssessor] = useState<any>(null);
  // const [oneAssessor, setSingleAssessor] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');

  // const [rowId, setRowId] = useState<Number | null>(null);
  // const [edits, setEdit] = useState(false);
  const navigate = useNavigate();
  const userlocal = localStorage.getItem('user');
  console.log(userlocal);
  // const handleClose = () => {
  //   // setOpen(false)
  //   setIsVisible(false);
  // };

    useEffect(() => {
      const savedToggleState = localStorage.getItem('showActiveOnly');
      if (savedToggleState !== null) {
        setShowActiveOnly(savedToggleState === 'true');
      } else {
        setShowActiveOnly(true);
      }
    }, []);
    useEffect(() => {
      localStorage.setItem('showActiveOnly', String(showActiveOnly));
    }, [showActiveOnly]);

    // Load toggle state from localStorage on component mount
  // useEffect(() => {
  //   const savedToggleState = localStorage.getItem('showActiveOnly');
  //   if (savedToggleState !== null) {
  //     setShowActiveOnly(savedToggleState === 'true');
  //   } else {
  //     setShowActiveOnly(true); // Default to true if no saved state
  //   }
  // }, []);
  
    const handleToggle = (event: any) => {
      setShowActiveOnly(event.target.checked);
    };

  const goToAddAssessorPage = () => {
    navigate('/addAssessorUser');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const filteredAssessors = searchQuery
    ? assessor.filter((assessors: any) => {
        const searchLower = searchQuery.toLowerCase();
        const searchUpper = searchQuery.toUpperCase();

        const idMatch = assessors.id.toString().toLowerCase().includes(searchLower);
        const UserNameMatch =
          assessors.UserUsername.toLowerCase().includes(searchLower) ||
          assessors.UserUsername.toUpperCase().includes(searchUpper);
        const FNameMatch =
          assessors.UserFname.toLowerCase().includes(searchLower) ||
          assessors.UserFname.toUpperCase().includes(searchUpper);
        const LNameMatch =
          assessors.UserLname.toLowerCase().includes(searchLower) ||
          assessors.UserLname.toUpperCase().includes(searchUpper);
        const EmailMatch =
          assessors.UserEmail.toLowerCase().includes(searchLower) ||
          assessors.UserEmail.toUpperCase().includes(searchUpper);
          const UserMatch =
          assessors.UserStatus.toLowerCase().includes(searchLower) ||
          assessors.UserStatus.toUpperCase().includes(searchUpper);

        return idMatch || UserNameMatch || FNameMatch || LNameMatch || EmailMatch || UserMatch;
      })
    : assessor;

// const finalFilteredAssessors = showActiveOnly
// ? filteredAssessors.filter((singleAssessor: any) => singleAssessor.UserStatus === 'active')
// : filteredAssessors;

// Ensure assessors exist before filtering
// let finalFilteredAssessors = [];

// if (filteredAssessors) {
//   finalFilteredAssessors = showActiveOnly
//     ? filteredAssessors.filter((singleAssessor: any) => singleAssessor.UserStatus.toLowerCase() === 'active')
//     : filteredAssessors.filter((singleAssessor: any) => singleAssessor.UserStatus.toLowerCase() === 'inactive');
// }
let finalFilteredAssessors = [];

if (filteredAssessors) {
  finalFilteredAssessors = showActiveOnly
    ? filteredAssessors.filter((singleAssessor: any) => singleAssessor.UserStatus.toLowerCase() === 'active')
    : filteredAssessors; // Show all assessors when toggle is off
}


  useEffect(() => {
    const fetchAssessors = async () => {
      try {
        const response = await axios.get('/core/getAllAssessors');
        const newArray = response.data.map(({ ID, ...rest }: any) => ({
          id: ID,
          ...rest,
        }));
        console.log(newArray);
        const neArray = newArray.map((userv: any) => ({
          ...userv,
          // UserLastLogin:
          //   userv.UserLastLogin === null || userv.UserLastLogin === undefined
          //     ? '1970-01-01 00:00:00'
          //     : convertStringToDate(userv.UserLastLogin), // Convert LastLogin to Date
          // UserCreatedAt: convertStringToDate(userv.UserCreatedAt), // Convert CreatedAt to Date
          UserLastLogin:
            userv.UserLastLogin === null ||
            userv.UserLastLogin === undefined ||
            userv.UserLastLogin === '0'
              ? '1970-01-01 00:00:00'
              : userv.UserLastLogin, // Convert LastLogin to Date
          UserCreatedAt: userv.UserCreatedAt, // Convert CreatedAt to Date
        }));
        console.log(neArray);

        setAssessor(neArray);
      } catch (error) {
        console.error('Error fetching assessors:', error);
      }
    };
    fetchAssessors();
  }, []);

  // function convertStringToDate(dateString: string): Date {
  //   return new Date(dateString);
  // }

  const handleView = (value: string, edit: boolean) => {
    goToViewAssessor(value, edit);
  };

  const goToSkillsetPage = () => {
    navigate('/skillset', { state: { data: _dataGrid } });
  };
  const goToWorkloadPage = () => {
    navigate('/workload');
  };

  const goToWorkSchedulePage = () => {
    navigate('/workscheduleassessor');
  };

  const goToLeaveManagement = () => {
    navigate('/leavemanagement', { state: { data: assessor } });
  };

  const goToViewAssessor = (id: any, edit: any) => {
    navigate(`/viewassessor?id=${id}&edit=${edit}&section=overview`);
  };

  console.log('Skillset data=', skillsetData);

  const goToAssessorTemplates = () => {
    navigate('/assessorTemplates');
  };

  const goToChecklist = () => {
    navigate('/checklist');
  };

  return (
    <Container sx={{ maxWidth: '1710px !important', marginTop: 2 }}>
      {!isVisible && (
        <><Grid container sx={{ marginTop: 2, marginBottom: 2 }} justifyContent="space-between">
          {(userrole === 'Management' || userrole === 'Workflow') && (
            <Grid item>
              <Tooltip
                title="This button provides feature to Create a New Assessor."
                placement="top"
              >
                <Button
                  variant="contained"
                  size="medium"
                  onClick={goToAddAssessorPage}
                  sx={{ borderRadius: 0 }}
                >
                  Add Assessor User
                </Button>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Tooltip
              title="This button provides feature to See The Skillset of Assessors"
              placement="top"
            >
              <Button
                variant="contained"
                size="medium"
                onClick={goToSkillsetPage}
                sx={{ borderRadius: 0 }}
              >
                Skillset
              </Button>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip
              title="This button provides feature to See The Workload of Assessors"
              placement="top"
            >
              <Button
                variant="contained"
                size="medium"
                onClick={goToWorkloadPage}
                sx={{ borderRadius: 0 }}
              >
                Workload
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title="This button provides feature to See The Work Schedule of Assessors"
              placement="top"
            >
              <Button
                variant="contained"
                size="medium"
                onClick={goToWorkSchedulePage}
                sx={{ borderRadius: 0 }}
              >
                Workschedule
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title="This button provides feature to Manage Leaves/Holidays Of Assessors"
              placement="top"
            >
              <Button
                variant="contained"
                size="medium"
                onClick={goToLeaveManagement}
                sx={{ borderRadius: 0 }}
              >
                Leave Management
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title="This button provides feature to Create Or Edit Assessors Templates"
              placement="top"
            >
              <Button
                variant="contained"
                size="medium"
                onClick={goToAssessorTemplates}
                sx={{ borderRadius: 0 }}
              >
                Assessor Templates
              </Button>
            </Tooltip>
          </Grid>

          {userrole === 'Management' && (
            <Grid item>
              <Tooltip title="This button provides feature to Manage Checklists" placement="top">
                <Button
                  variant="contained"
                  size="medium"
                  onClick={goToChecklist}
                  sx={{ borderRadius: 0 }}
                >
                  Checklist
                </Button>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search Assessors"
              type="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="bi:search" width={24} />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={handleSearchChange}
              title="Search will be applicable for Id, User Name, User First Name, User Last Name and Email" />
            <Grid item sx={{ color: 'red', marginTop: 1 }}>
              <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 900 }}>
                *Red indicates leave or work day off
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <FormControlLabel
                control={<Switch
                  checked={showActiveOnly}
                  // onChange={(event) => setShowActiveOnly(event.target.checked)}
                  onChange={handleToggle}
                  color="primary" />}
                  label={  
                  <strong>{showActiveOnly ? "Active Assessors" : "All Assessors"} </strong>
                }
                /> 
            </Box>
          </Grid></> 
      )}

      {!isVisible && filteredAssessors !== null && (
        <DataGridAssessor
          data={finalFilteredAssessors} 
          sendData={(value, edit) => {
            handleView(value, edit);
          }}
        />
      )}
    </Container>
  );
}

const _dataGrid = [...Array(40)].map((_, index) => ({
  id: index + 1,
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  lastLogin: _mock.time(index),
  occupency: _mock.number.percent(index),
  phoneNumber: _mock.phoneNumber(index),
  status: randomInArray(['active', 'inactive']),
  address: _mock.address.fullAddress(index),
  // firstName: `${_mock.name.lastName(index)}  ${_mock.name.firstName(index)}`,
  // lastName: _mock.name.firstName(index),
  createdDate: _mock.time(index),
}));

// const _workLoadDetails = [...Array(40)].map((_, index) => ({
//   id: index + 1,
//   name: _mock.name.fullName(index),
//   single: randomInArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
//   double: randomInArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
//   raDiy: randomInArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
//   finals: randomInArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
//   query: randomInArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
//   simulation: randomInArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
//   onHold: randomInArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
// }));

// const _assessorDetails = [...Array(40)].map((_, index) => ({
//   id: index + 1,
//   name: _mock.name.fullName(index),
// }));
