import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  // styled,
  // Checkbox,
  // MenuItem,
  // Box,
  Paper,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Container,
  Link,
  MenuItem,
  Divider,
  FormControlLabel,
  Switch
} from '@mui/material';
import axios from 'src/utils/axios';
// import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid';
// import { id } from 'date-fns/locale';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import {
  //  useLocation,
  useNavigate,
} from 'react-router-dom';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import Editor from 'src/components/editor/Editor';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from 'src/components/snackbar';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';

interface Templates {
  ID: number;
  TemplateName: string;
  TemplateText: string;
  EntryBy: number | null;
  CreatedDate: string;
  UpdatedDate: string;
  Category: string;
  UserRole?: string;
}

const userRoles = ['EA Assessor', 'Workflow'];

export default function AssessorTemplates() {
  // const location = useLocation();

  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedUserRole, setSelectedUserRole] = useState('EA Assessor');

  // const [resultObject, setResultObject] = useState([]);
  const [data, setData] = useState<Templates[]>([]);
  // const [categories, setCategories] = useState<string[]>([]);
  // const [selectedCategory, setSelectedCategory] = useState('');

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const topic = 'template'; 
  //       const keywordName = 'category'; 
  //       console.log(`Fetching categories with Topic: ${topic}, KeywordName: ${keywordName}`);

  //       const response = await axios.get('/core/getKeywordValueConfig', {
  //         params: {
  //           Topic: topic,
  //           KeywordName: keywordName,
  //         },
  //       });
  //       console.log('API ccccc Response:', response.data);

  //       // Check for keyword values in the response
  //       const keywordValues = response.data?.keyword_value;
  //       console.log('Fetched categories:', keywordValues);
  //       if (keywordValues && Array.isArray(keywordValues)) {
  //         setCategories(keywordValues);
  //       } else {
  //         console.error('Invalid response structure:', response.data);
  //       }
  //     } catch (error) {
  //       console.error('Failed to fetch categories:', error);
  //     }
  //   };

  //   fetchCategories();
  // }, []);

  const getTemplates = useCallback(() => {
    axios
      .get(`/core/getAllTemplates`)
      .then((response) => {
        console.log('Templates = ', response.data);
        setData(response.data as Templates[]);
        console.table(response.data, ['ID', 'TemplateName', 'TemplateText', 'Category']);
      })
      .catch((error) => {
        console.error('Error fetching get all templates:', error);
      });
  }, []);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const [editor, setEditor] = useState(``);

  const { themeStretch } = useSettingsContext();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    getTemplates();
    setOpen(false);
  };

  const addTemplate = () => {
    setEdit(false);
    setnName(null);
    setEditor('');
    setSelectedUserRole('EA Assessor');
    setOpen(true);
    // setSelectedCategory('');
  };
  const [edit, setEdit] = useState(false);
  const [name, setnName] = useState<any>(null);

  const editTemplate = (template: Templates) => {
    setEditor(template.TemplateText);
    setnName(template);
    setEdit(true);
    // setSelectedCategory(template.Category);
    setOpen(true);
  };

  const delTemplate = (template: Templates) => {
    console.log('Delete Template : ', template);
    axios
      .delete(`/core/delete-assessor-template?id=${template.ID}`)

      .then((response) => {
        console.log(response.data.message);
        enqueueSnackbar(response.data.message);
        getTemplates();
      })
      .catch((error) => {
        enqueueSnackbar(error);
      });
  };

  const goToAssessorList = () => {
    navigate('/assessorlist');
  };

  const [showEAAssessor, setShowEAAssessor] = useState(true);
  const filteredData = data?.filter(row => {
    // Default to 'EA Assessor' if UserRole is not present
    const userRole = row.UserRole || 'EA Assessor';
    if (showEAAssessor) {
      return userRole === 'EA Assessor';
    }
    return userRole === 'Workflow';
  });
  // const filteredData = data?.filter(row => {
  //   // Default to 'EA Assessor' if UserRole is not present
  //   if (showEAAssessor) {
  //     return row.UserRole === 'EA Assessor';
  //   } 
  //     return row.UserRole === 'Workflow';
  // });

  console.log('Toggle State:', showEAAssessor);
  console.log('Filtered Data:', filteredData);

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      <Helmet>
        <title> Assessor | Energy Advance</title>
      </Helmet>

      <EditOrUpdateTemplates
        open={open}
        onClose={onClose}
        editor={editor}
        setEditor={setEditor}
        edit={edit}
        template={name}
        selectedUserRole={selectedUserRole} // Pass the state
        setSelectedUserRole={setSelectedUserRole}
      />
      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}

      <CustomBreadcrumbs
        heading="Email Templates"
        links={[
          {
            name: 'Assessor',
            href: '/assessorlist',
          },
          { name: 'Email Templates' },
        ]}
      />
      <Grid container sx={{ marginTop: 2, marginBottom: 2 }} justifyContent="space-between">
        {/* <Grid item>
            <TextField
              variant="outlined"
              size="small"
              value={searchValue}
              placeholder="Search Assessors"
              type="search"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="bi:search" width={24} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid> */}
      </Grid>
      <Grid container sx={{ marginTop: 2, marginBottom: 2 }} justifyContent="space-between">
        <Grid item>
          {user?.UserRole !== 'Assessor' && (
            <Button
              variant="contained"
              size="medium"
              onClick={addTemplate}
              sx={{ borderRadius: 0 }}
            >
              Add Template
            </Button>
          )}
        </Grid>
        {/* <Divider orientation="vertical" flexItem sx={{  height: 32, borderWidth: '1.5px' }} /> */}
        <FormControlLabel
          control={
            <Switch
              checked={showEAAssessor}
              onChange={(e) => setShowEAAssessor(e.target.checked)}
              color="primary"
            />
          }
          label={showEAAssessor ? "User Role - Assessor" : "User Role - Workflow "}
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '5%' }}>ID</TableCell>
              <TableCell sx={{ width: '12%' }}>User Role</TableCell>
              <TableCell sx={{ width: '12%' }}>Template Name</TableCell>
              <TableCell sx={{ width: '10%' }}>Category</TableCell>
              <TableCell sx={{ width: '70%' }}>Template</TableCell>
              <TableCell>Action Edit</TableCell>
              <TableCell sx={{ width: '10%' }}>Action Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map(
              (item: Templates, index: any) =>
                item && (
                  <>
                    <TableRow key={index}>
                      <TableCell>{item.ID}</TableCell>
                      {/* <TableCell>{item.UserRole}</TableCell> */}
                      <TableCell>{item.UserRole || 'EA Assessor'}</TableCell>
                      <TableCell>{item.TemplateName}</TableCell>
                      <TableCell>{item.Category}</TableCell>
                      <TableCell sx={{ width: '70%' }}>
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: item.TemplateText,
                          }}
                          style={{ margin: 0, padding: 0 }}
                        >
                          {/* {email.EmailSummary} */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {(user?.UserRole === 'Management' || user?.UserRole === 'Workflow') && (
                          <Link sx={{ width: '10%' }} onClick={() => editTemplate(item)}>
                            {' '}
                            <Iconify icon="line-md:edit" /> Edit
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        {(user?.UserRole === 'Management' || user?.UserRole === 'Workflow') && (
                          <Link sx={{ color: 'red', width: '10%' }} onClick={() => delTemplate(item)}>
                            {' '}
                            <Iconify icon="fluent:delete-16-filled" /> Delete
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Divider sx={{ my: 1, borderWidth: '1.7px', }} />
                      </TableCell>
                    </TableRow>
                  </>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="soft"
        color="secondary"
        onClick={goToAssessorList}
        sx={{ float: 'right', marginTop: '20px', mt: 1 }}
      >
        Back
      </Button>
      {/* </Container> */}
    </Container>
  );
}

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  editor: string;
  setEditor: Dispatch<SetStateAction<string>>;
  edit?: boolean;
  template: Templates;
  selectedUserRole: string; // Add this prop
  setSelectedUserRole: Dispatch<SetStateAction<string>>;
}

type FormValuesProps = {
  Template: string;
  Category: string;
  UserRole: string;
};

function EditOrUpdateTemplates({
  open,
  onClose,
  editor,
  setEditor,
  edit = false,
  template,
  selectedUserRole, // Receive the prop
  setSelectedUserRole,
}: Props) {
  const { user } = useAuthContext();
  // const [data, setData] = useState<Templates[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [jobContent, setJobContent] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  // const [selectedUserRole, setSelectedUserRole] = useState('EA Assessor');
  const editorRef = useRef<HTMLDivElement>(null);
  const CommentSchema = Yup.object().shape({
    Template: Yup.string(),
    UserRole: Yup.string().required('User Role is required'),

  });
  const defaultValues = {
    Template: '',
    Category: '',
    UserRole: template?.UserRole || 'EA Assessor',
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const topic = 'template';
        const keywordName = 'category';
        console.log(`Fetching categories with Topic: ${topic}, KeywordName: ${keywordName}`);

        const response = await axios.get('/core/getKeywordValueConfig', {
          params: {
            Topic: topic,
            KeywordName: keywordName,
          },
        });
        console.log('API Response:', response.data);

        const keywordValues = response.data?.keyword_value;
        console.log('Fetched categories:', keywordValues);

        if (keywordValues && typeof keywordValues === 'string') {
          const categoriesArray = keywordValues.split(',').map(category => category.trim());
          setCategories(categoriesArray);
        } else {
          console.error('Invalid response structure:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, []);
  const handleCategoryChange = (event: any) => {
    console.log('Selected Category:', event.target.value);  // Log the new category selection
    setSelectedCategory(event.target.value);
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState,
    // formState: { isSubmitting },
  } = methods;
  const values = watch();
  console.log('Edit in EditOrUpdateTemplates =', edit);
  console.log('Template name =', template);

  useEffect(() => {
    console.log('In useEffect, edit =', edit);
    console.log('Template value =', template);

    if (
      template &&
      typeof template === 'object' &&
      'TemplateName' in template &&
      template.TemplateName
    ) {
      setValue('Template', template.TemplateName);
      console.log('Setting Template to:', template.TemplateName);

      if ('Category' in template && template.Category) {
        setValue('Category', template.Category);
        setSelectedCategory(template.Category);
        console.log('Setting Category to:', template.Category);
      } else {
        console.log('No Category found in template');
        setValue('Category', '');
        setSelectedCategory('');
      }

      // Set UserRole when editing
      if ('UserRole' in template && template.UserRole) {
        setSelectedUserRole(template.UserRole);
        setValue('UserRole', template.UserRole);
      }

    } else {
      console.log('In useEffect, else block triggered');
      console.log('Edit state =', edit);
      console.log('Template value else =', template);

      setValue('Template', '');
      setValue('Category', '');
      setSelectedCategory('');
      setEditor('');
    }
  }, [edit, setEditor, setSelectedUserRole, setValue, template]);


  const handleUserRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newRole = event.target.value as string;
    setSelectedUserRole(newRole);
    setValue('UserRole', newRole);

    if (newRole === 'Workflow') {
      setSelectedCategory('Other');
      setValue('Category', 'Other');
    } else {
      // Clear category when switching to EA Assessor
      setSelectedCategory('');
      setValue('Category', '');
    }
  };

  const [isloading, setisLoading] = useState(false);

  const onValueChanges = (value: any) => {
    setEditor(value);
    // console.log("Editor value = " , editor);
  };

  // const handleTypeChange = (event: any) => {
  //   const selectedValue = `{{ ${event.target.value} }}`;
  //   let combined_value;
  //   if (editor === '' || editor.trim() === '<p></p>') {
  //     combined_value = `<p>${selectedValue}</p>`;
  //   } else {
  //     combined_value = editor.replace(/<\/p>$/, ` ${selectedValue}</p>`);
  //   }
  //   setEditor(combined_value);
  //   console.log('Editor value =', combined_value);
  // };
  const handleDropdownChange = (event: any) => {
    setJobContent(event.target.value);
  };
  const handleClose = () => {
    reset();
    setEditor('');
    setSelectedCategory('');
    setJobContent('');
    setSelectedUserRole('EA Assessor');
    setValue('UserRole', 'EA Assessor');
    onClose();
  };

  const handleTypeChange = () => {
    if (jobContent !== '') {
      const selectedValue = `{{ .${jobContent} }}`;
      let combined_value;
      if (editor === '' || editor.trim() === '<p></p>') {
        combined_value = `<p>${selectedValue}&nbsp;</p>`;
      } else {
        combined_value = editor
          .replace(/<p><br><\/p>/g, '')
          .replace(/<\/p>$/, `${selectedValue}&nbsp;</p>`);
      }
      setEditor(combined_value);
      setJobContent('');
      // }
      // };
      // Move cursor to the end of the content
      setTimeout(() => {
        const editorElement = document.querySelector('.ql-editor') as HTMLElement;
        if (editorElement) {
          editorElement.focus();
          const range = document.createRange();
          const selection = window.getSelection();
          if (selection) {
            range.selectNodeContents(editorElement);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      }, 0);
    }
  };
  const [data, setData] = useState<Templates[]>([]);
  const [existingTemplates, setExistingTemplates] = useState<string[]>([]);
  const getTemplates = useCallback(() => {
    axios
      .get(`/core/getAllTemplates`)
      .then((response) => {
        console.log('Templates = ', response.data);
        setData(response.data as Templates[]);
        // Extract template names to check for duplicates
        const templateNames = response.data.map((template1: Templates) => template1.TemplateName.trim());
        setExistingTemplates(templateNames);
        console.table(response.data, ['ID', 'TemplateName', 'TemplateText', 'Category']);
      })
      .catch((error) => {
        console.error('Error fetching get all templates:', error);
      });
  }, []);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const onSubmit = async (dat: FormValuesProps) => {
    try {
      await CommentSchema.validate(dat, { abortEarly: false });

      const trimmedTemplate = values.Template?.trim();
      const trimmedEditor = editor?.trim();
      const trimmedCategory = selectedCategory?.trim();
      const updatedEditor = trimmedEditor?.replace(/<p\b([^>]*)>/gi, '<div$1>').replace(/<\/p>/gi, '</div>');
      const contentLength = updatedEditor?.replace(/<[^>]+>/g, '').length || 0;

      const lowercaseTrimmedTemplate = trimmedTemplate.toLowerCase();
      const lowercaseExistingTemplates = existingTemplates.map((name) => name.toLowerCase());

      if (!edit && lowercaseExistingTemplates.includes(lowercaseTrimmedTemplate)) {
        enqueueSnackbar("Template name already exists!", { variant: "error" });
        return;
      }

      if (edit) {
        const otherTemplates = data.filter((t) => t.ID !== template.ID);
        const otherTemplateNames = otherTemplates.map((t) => t.TemplateName.trim().toLowerCase());
        if (otherTemplateNames.includes(lowercaseTrimmedTemplate)) {
          enqueueSnackbar("Template name already exists!", { variant: "error" });
          return;
        }
      }

      if (trimmedTemplate && updatedEditor && trimmedCategory && contentLength >= 1) {
        setisLoading(true);

        if (edit) {
          const payload = {
            template_name: trimmedTemplate,
            template_text: updatedEditor,
            category: trimmedCategory,
            user_role: selectedUserRole,
            entry_by: user?.ID,
            ...(edit && { id: template.ID })
          };
          axios
            .put(`/core/update-assessor-template`, payload)
            .then((response) => {
              enqueueSnackbar(response.data);
              onClose();
              setisLoading(false);
            })
            .catch((error) => {
              enqueueSnackbar(error);
              setisLoading(false);
            });
        } else {
          const payload = {
            template_name: trimmedTemplate,
            template_text: updatedEditor,
            category: trimmedCategory,
            user_role: selectedUserRole,
            entry_by: user?.ID,
          };
          axios
            .post(`/core/create-assessor-template`, payload)
            .then((response) => {
              enqueueSnackbar(response.data);
              onClose();
              setValue('Template', '');
              setSelectedCategory('');
              setisLoading(false);
            })
            .catch((error) => {
              enqueueSnackbar(error);
              setisLoading(false);
            });
        }
      } else {
        enqueueSnackbar('Template Name, Category and Content are mandatory to fill.', { variant: 'error' });
        setisLoading(false);
      }
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  };
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {' '}
        {edit && 'Edit'} {!edit && 'Add'} Template{' '}
      </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2}>
            {/* UserRole Dropdown */}
            <RHFSelect
              name="UserRole"
              label="Select User Role"
              value={selectedUserRole}
              onChange={handleUserRoleChange}
              variant="outlined"
              disabled={edit}
            >
              {userRoles.map((role: any, index: any) => (
                <MenuItem key={index} value={role}>
                  {role}
                </MenuItem>
              ))}
            </RHFSelect>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            {/* <RHFTextField name="Template" label="Template Name" /> */}
            <RHFTextField
              name="Template"
              label="Template Name"
              error={Boolean(formState.errors.Template)}
              helperText={formState.errors.Template?.message}
            />

            <Stack direction="row" spacing={2} >
              <RHFSelect
                name="Category"
                label="Select Category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                variant="outlined"
                disabled={selectedUserRole === 'Workflow'}
              >
                <MenuItem value="">
                  {/* <em>None</em> */}
                </MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant="subtitle1">Template Content</Typography>
              <Stack direction="row" spacing={2} sx={{ width: '25%' }}>
                <RHFSelect
                  name="Type"
                  label="Select Job Content"
                  value={jobContent}
                  onChange={handleDropdownChange} // Capture dropdown value
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="JobNumber">Job Number</MenuItem>
                  <MenuItem value="JobType">Job Type</MenuItem>
                  <MenuItem value="JobAddress">Job Address</MenuItem>
                  <MenuItem value="JobRating">Job Rating</MenuItem>
                  <MenuItem value="JobFloors">Job Floors</MenuItem>
                  <MenuItem value="JobAddrState">Job Address State</MenuItem>
                  <MenuItem value="JobCode">Job Code</MenuItem>
                  <MenuItem value="JobPrelimFinal">Prelim / Final</MenuItem>
                  <MenuItem value="NccType">Ncc type</MenuItem>
                  <MenuItem value="AwohValid">AWOH Valid</MenuItem>
                  <MenuItem value="JobAddJobcode">Additional Job Code</MenuItem>
                  <MenuItem value="JobDwelling"> Job Dwelling </MenuItem>
                  <MenuItem value="JobCreatedAt">Job Created At</MenuItem>
                  <MenuItem value="JobVersion">Version</MenuItem>
                </RHFSelect>
                <Button variant="outlined" color="secondary" onClick={handleTypeChange}>
                  Add
                </Button>
              </Stack>
            </Stack>
            <Editor
              value={editor}
              onChange={onValueChanges}
              onchan={(newValue) => setEditor(newValue)}
              simple
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isloading} type="submit">
            {edit && 'Update'} {!edit && 'Create'} Template
          </LoadingButton>

          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
