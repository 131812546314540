import { useEffect, useState, useCallback } from 'react';
import axios from 'src/utils/axios';
// import { useSnackbar } from 'src/components/snackbar';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  // styled,
  // Checkbox,
  MenuItem,
  // Box,
  Paper,
} from '@mui/material';
// import { useAuthContext } from 'src/auth/useAuthContext';
// import label from 'src/components/label';
// import FileThumbnail from 'src/components/file-thumbnail';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {
  // RHFRadioGroup,RHFTextField,
  RHFSelect,
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
// components
// import Editor from 'src/components/editor';
// import Iconify from '../../components/iconify';
// import { Upload } from '../../components/upload';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  setEditor: (value: any) => void;
  job_data: any;
}

interface Signature {
  UserID: number;
  Signature: string;
  EntryBy?: number;
}

interface Templates {
  ID: number;
  TemplateName: string;
  Category: string;
  TemplateText: string;
  EntryBy: number | null;
  CreatedDate: string;
  UpdatedDate: string;
  UserRole: string;
}

type FormValuesProps = {
  Template: string;
  Signature: string;
  Category: string;
  UserRole: string;
};

const defaultValues = {
  Template: '',
  Signature: '',
  Category: '',
  UserRole: '',
};

export default function SelectTemplate({ open, onClose, setEditor, job_data }: Props) {
  const [data, setData] = useState<Templates[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const CommentSchema = Yup.object().shape({
    Template: Yup.string(),
    Category: Yup.string(),
  });
  // const defaultValues = {
  //   Template: '',
  //   Signature: '',
  //   Category: '',
  // };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (open)  {
    axios
      .get(`/core/getAllTemplates`)
      .then((response) => {
        //        console.log('Job status date 1 = ', response.data);
        setData(response.data as Templates[]);
        console.table(response.data);
      })
      .catch((error) => {
        console.error('Error fetching getAllTemplates:', error);
      });
    }
  }, [open]);

  const [signature, setSignature] = useState<Signature[]>([]);

  //     useEffect(() => {
  //       axios
  //     .get(`/core/getallusersigns`)
  //     .then((response) => {
  //       setSignature(response.data as Signature[])
  // //      console.log(signature,response.data)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching status date:', error);
  //     })
  //   }, [setSignature, signature]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState<Templates[]>([]);
  const [selectedUserRole, setSelectedUserRole] = useState('');

  const handleUserRoleChange = (event: any) => {
    const newUserRole = event.target.value;
    setSelectedUserRole(newUserRole);
    
    // Auto-select category as "Other" for Workflow role
    if (newUserRole === "Workflow") {
      setSelectedCategory("Other");
      setValue('Category', "Other");
      const filtered = data.filter((item) => item.Category === "Other");
      setFilteredTemplates(filtered);
    } else {
      setSelectedCategory('');
      setValue('Category', '');
      setFilteredTemplates([]);
    }
    
    setSelectedTemplate('');
    setValue('Template', '');
  };

  const handleCategoryChange = (event: any) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    const filtered = data.filter((item) => item.Category === newCategory);
    setFilteredTemplates(filtered);
    setSelectedTemplate('');
    setValue('Template', '');
  };

  const handleTemplateChange = (event: any) => {
    const selectedTemplateName = event.target.value;
    setSelectedTemplate(selectedTemplateName);

    const selectedTemplateText =
      data.find((item) => item.TemplateName === selectedTemplateName)?.TemplateText || '';

    setValue('Template', selectedTemplateText);
  };

  const handleClose = () => {
    reset();
    setSelectedUserRole('');
    setSelectedCategory('');
    setSelectedTemplate('');
    setFilteredTemplates([]);
    onClose();
  };

  const handleSelectChange = (event: any) => {
    const selectedTemplateName = event.target.value;

    // Find the TemplateText that corresponds to the selected TemplateName
    const selectedTemplateText =
      data.find((item) => item.TemplateName === selectedTemplateName)?.TemplateText || '';

    // Update the selectedTemplate state (if needed)
    setSelectedTemplate(selectedTemplateName);

    // Set the value of the "Template" field to the corresponding TemplateText
    setValue('Template', selectedTemplateText);

    console.log('Selected TemplateName:', selectedTemplateName);
    console.log('Matching TemplateText:', selectedTemplateText);
  };

  const [isloading, setisLoading] = useState(false);
  const onSubmit = useCallback(
    async (dat: FormValuesProps) => {
      try {
        const requestBody = {
          jobId: job_data?.JobRecordID,
          templateText: dat.Template,
        };
        await axios.put(`/core/useTemplate`, requestBody).then((response) => {
          // enqueueSnackbar(response?.data?.result);
          console.log('template response: ', response?.data);
          // setEditor(response?.data?.result);
          setEditor((prev: any)=> 
            `${prev} ${response?.data?.result}`
          );
          // Reset form fields after submitting
          reset(defaultValues);
          setSelectedCategory('');
          setSelectedTemplate('');
          setFilteredTemplates([]);
        });
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        console.error('Error with Template Processing:', error);
      } finally {
        try {
          // setEditor(dat.Template + dat.Signature);
          onClose();
        } catch (error) {
          console.error(error);
        }
      }
    },

    [job_data?.JobRecordID, enqueueSnackbar, setEditor, onClose, reset]
  );

  useEffect(() => {
    setValue('Template', '');
    setValue('Signature', '');
    setValue('Category', '');
  }, [setValue, onSubmit, onClose]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> Select Template </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2}>
            <RHFSelect 
              name="UserRole" 
              label="User Role" 
              value={selectedUserRole} 
              onChange={handleUserRoleChange}
            >
              <MenuItem value="EA Assessor">EA Assessor</MenuItem>
              <MenuItem value="Workflow">Workflow</MenuItem>
            </RHFSelect>

            <RHFSelect 
              name="Category" 
              label="Category" 
              value={selectedCategory} 
              onChange={handleCategoryChange}
              disabled={!selectedUserRole}
            >
              {Array.from(
                new Set(
                  data
                    .filter(item => item.UserRole === selectedUserRole)
                    .map(item => item.Category)
                )
              ).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFSelect 
              name="Template" 
              label="Template" 
              value={selectedTemplate} 
              onChange={handleTemplateChange}
              disabled={!selectedCategory}
            >
              {filteredTemplates
                .filter(item => item.UserRole === selectedUserRole)
                .map((item) => (
                  <MenuItem key={item.ID} value={item.TemplateName}>
                    {item.TemplateName}
                  </MenuItem>
                ))}
            </RHFSelect>

            <Typography variant="subtitle1">Template Content</Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: values.Template + values.Signature }}
              >
                {/* {email.EmailSummary} */}
              </Typography>
            </Paper>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isloading} type="submit"disabled={!selectedCategory || !selectedTemplate}>
            Use Template
          </LoadingButton>

          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
