import axios, { AxiosRequestConfig } from 'axios';
// import { AxiosRequestConfig } from 'axios';
import { PATH_AUTH } from 'src/routes/paths';
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------
declare module 'axios' {
  export interface AxiosRequestConfig {
      skipInterceptor?: boolean;
    }
}

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

let lastApiHitTime = Date.now();
let navigateFunction: any = null;
let logoutFunction: any = null;
// const idle_time = 65000;
const idle_time = 30 * 60 * 1000; // 30 minutes in milliseconds
let intervalId: NodeJS.Timeout | null = null;

// console.log ("lastApiHitTime :", lastApiHitTime)
axiosInstance.interceptors.response.use(
  (response) => {
    if (!response.config.skipInterceptor) {
      lastApiHitTime = Date.now();
    // } else {
    //   console.log("Found skip interceptor")
    }
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'WE ARE UNDER MAINTAINANCE :)')
);

// Periodically check for API inactivity
const startInactivityTimer = () => {
  if (intervalId) clearInterval(intervalId);
  intervalId = setInterval(() => {
    const now = Date.now();
    // console.log("Now time:", now, "Last API hit time:", lastApiHitTime);
    if (now - lastApiHitTime > idle_time) {
      console.log('No API hit in the last 90 seconds', now);
      if (logoutFunction) logoutFunction();
      if (navigateFunction) navigateFunction(PATH_AUTH.login, { replace: true });

      if (intervalId) {
        clearInterval(intervalId); 
        intervalId = null;
      }
    } else {
      console.log('API idle time not yet reached', now - lastApiHitTime , idle_time);
    }
  }, 170000); // 2 minutes and 50 seconds
};

// Function to inject navigate and logout functions
export const setNavigateAndLogoutFunction = (navigate: any, logout: any) => {
 navigateFunction = navigate;
 logoutFunction = logout;
  startInactivityTimer();
};

// export default axiosInstance;






// import axios from 'axios';
// // config
// import { HOST_API_KEY } from '../config-global';

// // ----------------------------------------------------------------------

// const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'WE ARE UNDER MAINTAINANCE :)')
// );

export default axiosInstance;