import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  // styled,
  // Checkbox,
  // MenuItem,
  // Box,
  Paper,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Container,
  Select,
  MenuItem,
  TextField,
  styled,
  tableCellClasses,
} from '@mui/material';
import axios from 'src/utils/axios';
// import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid';
// import { id } from 'date-fns/locale';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import {
  //  useLocation,
  useNavigate,
} from 'react-router-dom';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from 'src/components/snackbar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

interface ChecklistFields {
  que_topic: string;
  question: string;
  na_incl: string;
  notes_incl: string;
}

interface Templates {
  ID: number;
  TemplateName: string;
  TemplateText: string;
  EntryBy: number | null;
  CreatedDate: string;
  UpdatedDate: string;
}

interface ChecklistMaster {
  id: number;
  checklist_name: string;
  question_id: number;
  question: string;
  que_topic: 't' | 'q';
  na_included: 'Yes' | 'No';
  notes_included: 'Yes' | 'No';
  modified_date: string;
  modified_by: string;
}

export default function Checklist() {
  // const location = useLocation();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'inherit',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: 'inherit',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  // const [resultObject, setResultObject] = useState([]);
  const [data, setData] = useState<ChecklistMaster[]>([]);
  const [allChecklists, setAllChecklists] = useState<ChecklistMaster[]>([]);
  const getTemplates = useCallback(() => {
    axios
      .get(`/core/getChecklist`)
      .then((response) => {
        const checklistMap = new Map<string, ChecklistMaster>();

        response.data.forEach((item: any) => {
          if (!checklistMap.has(item.checklist_name)) {
            checklistMap.set(item.checklist_name, item);
          }
        });

        // Convert the Map back to an array
        const uniqueChecklistNames: ChecklistMaster[] = Array.from(checklistMap.values());
        uniqueChecklistNames.sort((a, b) => {
          if (a.id < b.id) return 1;
          if (a.id > b.id) return -1;
          return 0;
        });
        setData(uniqueChecklistNames as ChecklistMaster[]);
        console.log('');
        setAllChecklists(response.data);
      })
      .catch((error) => {
        console.error('Error fetching get all templates:', error);
      });
  }, []);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const [editor, setEditor] = useState(``);

  const { themeStretch } = useSettingsContext();
  const [open, setOpen] = useState(false);
  const [openJobStageChecklist, setOpenJobStageChecklist] = useState(false);
  const onClose = () => {
    getTemplates();
    setOpenJobStageChecklist(false);
    setOpen(false);
  };

  const addTemplate = () => {
    setEdit(false);
    setnName(null);
    setEditor('');
    setOpen(true);
  };

  const jobStageChecklist = () => {
    setEdit(false);
    setnName(null);
    setEditor('');
    setOpenJobStageChecklist(true);
  };
  const [edit, setEdit] = useState(false);
  const [name, setnName] = useState<any>(null);

  const editTemplate = (checklist: ChecklistMaster) => {
    const matchingChecklists = allChecklists.filter(
      (item) => item.checklist_name === checklist?.checklist_name
    );
    setAllChecklists(matchingChecklists);
    // setnName(template);
    setEdit(true);
    setOpen(true);
  };

  const delTemplate = (template: ChecklistMaster) => {
    console.log(template.checklist_name);
    axios
      .post(`/core/deleteChecklist?checklist_name=${template.checklist_name}`)
      .then((response) => {
        console.log(response.data);
        if (response?.data === 'Remove Stage Checklist Mapping') {
          console.log('In Info');
          enqueueSnackbar(response.data, { variant: 'warning' });
        } else {
          enqueueSnackbar(response.data);
          getTemplates();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error);
      });
  };

  const goToAssessorList = () => {
    navigate('/assessorlist');
  };

  return (
    <Container sx={{ maxWidth: '100% !important' }}>
      <Helmet>
        <title> Assessor | Energy Advance</title>
      </Helmet>

      <EditOrUpdateTemplates
        open={open}
        onClose={onClose}
        editor={editor}
        setEditor={setEditor}
        edit={edit}
        template={allChecklists}
      />

      <JobStageChecklist
        open={openJobStageChecklist}
        onClose={onClose}
        editor={editor}
        setEditor={setEditor}
        edit={edit}
        template={allChecklists}
      />
      {/* <Container sx={{ marginTop: '1%' }}> */}
      <CustomBreadcrumbs
        sx={{ height: '40px' }}
        heading="Checklists"
        links={[
          {
            name: 'Assessor',
            href: '/assessorlist',
          },
          { name: 'Checklists' },
        ]}
      />

      <Grid container sx={{ marginBottom: '10px' }}>
        <Grid item>
          {user?.UserRole !== 'Assessor' && (
            <Button variant="contained" size="medium" onClick={addTemplate}>
              Create Checklist
            </Button>
          )}
        </Grid>
        <Grid item>
          {user?.UserRole !== 'Assessor' && (
            <Button
              variant="contained"
              size="medium"
              onClick={jobStageChecklist}
              sx={{ marginLeft: '10px' }}
            >
              Job Stage Checklist
            </Button>
          )}
        </Grid>
      </Grid>
      <TableContainer style={{ height: '850px', overflow: 'auto' }}>
        <Table component={Paper} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#232222' }}>ID</TableCell>
              <TableCell sx={{ width: '70%', backgroundColor: '#232222' }}>
                Checklist Name
              </TableCell>
              <TableCell sx={{ backgroundColor: '#232222' }}>Edit</TableCell>
              <TableCell sx={{ backgroundColor: '#232222' }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ? data.map(
                  (item: ChecklistMaster, index: any) =>
                    item && (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{index + 1}.</StyledTableCell>
                        <StyledTableCell>{item.checklist_name}</StyledTableCell>

                        {/* <TableCell sx={{ width: '70%' }}>
                    <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: item.TemplateText }}
                    >
                        {email.EmailSummary}
                    </Typography>
                    </TableCell> */}
                        <StyledTableCell>
                          {(user?.UserRole === 'Management' || user?.UserRole === 'Workflow') && (
                            <Button onClick={() => editTemplate(item)}>
                              {' '}
                              <Iconify icon="line-md:edit" />
                              Edit
                            </Button>
                          )}
                        </StyledTableCell>
                        {/* <StyledTableCell>
                    {(user?.UserRole === 'Management' || user?.UserRole === 'Workflow') && (
                        <Button
                            sx={{ color: 'red', width: '15%' }}
                            onClick={() => delTemplate(item)}
                        >
                            {' '}
                            <Iconify icon="fluent:delete-16-filled" /> Delete
                        </Button>
                    )}
                    </StyledTableCell> */}

                        <StyledTableCell>
                          {(user?.UserRole === 'Management' || user?.UserRole === 'Workflow') && (
                            <Button sx={{ color: 'red' }} onClick={() => delTemplate(item)}>
                              {' '}
                              <Iconify icon="fluent-mdl2:delete" />
                              Delete
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                )
              : 'Loading...'}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="soft"
        color="secondary"
        onClick={goToAssessorList}
        sx={{ float: 'right', marginTop: '20px', mt: 1 }}
      >
        Back
      </Button>
      {/* </Container> */}
    </Container>
  );
}

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  editor: string;
  setEditor: Dispatch<SetStateAction<string>>;
  edit?: boolean;
  template: ChecklistMaster[];
}

type FormValuesProps = {
  Template: string;
};

function EditOrUpdateTemplates({
  open,
  onClose,
  editor,
  setEditor,
  edit = false,
  template,
}: Props) {
  const { user } = useAuthContext();
  // const [data, setData] = useState<Templates[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [checklistQuestions, setChecklistQuestions] = useState<ChecklistFields[]>([]);
  const [preChecklistName, setPreChecklistName] = useState('');
  const CommentSchema = Yup.object().shape({
    Template: Yup.string().required('Checklist name is required'),
  });
  const defaultValues = {
    Template: '',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (template !== null && edit === true) {
      setPreChecklistName(template[0]?.checklist_name);
      setValue('Template', template[0]?.checklist_name);
      const newChecklistQuestions = template.map((item) => ({
        que_topic: item.que_topic,
        question: item.question,
        na_incl: item.na_included,
        notes_incl: item.notes_included,
      }));
      setChecklistQuestions(newChecklistQuestions);
    } else {
      setValue('Template', '');
      setEditor('');
      setChecklistQuestions([]);
    }
  }, [edit, setEditor, setValue, template]);

  const handleChangeField = (index: number, field: keyof ChecklistFields, value: any) => {
    const createChecklistQuestions = [...checklistQuestions];
    createChecklistQuestions[index] = {
      ...createChecklistQuestions[index],
      [field]: value,
    };
    setChecklistQuestions(createChecklistQuestions);
  };

  const handleRemoveQuestion = (index: number) => {
    setChecklistQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
  };

  const handleAddChecklistQuestion = () => {
    setChecklistQuestions([
      ...checklistQuestions,
      {
        que_topic: '',
        question: '',
        na_incl: '',
        notes_incl: '',
      } as ChecklistFields,
    ]);
  };

  const [isloading, setisLoading] = useState(false);

  const onValueChanges = (value: any) => {
    setEditor(value);
  };
  const onSubmit = async (dat: FormValuesProps) => {
    try {
      await CommentSchema.validate(dat, { abortEarly: false });
      setisLoading(true);

      if (edit) {
        const payload = {
          prev_checklist_name: preChecklistName,
          new_checklist_name: values.Template,
          modified_by: String(user?.ID),
          questions: checklistQuestions,
        };
        console.log('Update checklist payload = ', payload);
        if (checklistQuestions.length === 0) {
          enqueueSnackbar('Enter at least one Question or Topic', { variant: 'error' });
          setisLoading(false);
        } else {
          axios
            .post(`/core/updateChecklist`, payload)
            .then((response) => {
              enqueueSnackbar(response.data);
              onClose();
              setisLoading(false);
            })
            .catch((error) => {
              enqueueSnackbar(error);
              setisLoading(false);
            });
        }
      } else {
        const updatedChecklistQuestions = checklistQuestions.map((item) => {
          if (item.que_topic === 't') {
            return { ...item, na_incl: 'No', notes_incl: 'No' };
          }
          return item;
        });
        const payload = {
          checklist_name: values.Template,
          modified_by: String(user?.ID),
          questions: updatedChecklistQuestions,
        };
        console.log('Create checklist paylod = ', payload);
        if (checklistQuestions.length === 0) {
          enqueueSnackbar('Enter at least one Question or Topic', { variant: 'error' });
          setisLoading(false);
        } else
          axios
            .post(`/core/createChecklist`, payload)
            .then((response) => {
              enqueueSnackbar(response.data);
              onClose();
              setisLoading(false);
              setChecklistQuestions([]);
            })
            .catch((error) => {
              enqueueSnackbar(error, { variant: 'error' });
              setisLoading(false);
            });
      }
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {' '}
        {edit && 'Edit'} {!edit && 'Add'} Checklist{' '}
      </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2} sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            <RHFTextField name="Template" label="Checklist Name" />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle1">Checklist Content</Typography>
              <Button
                size="small"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleAddChecklistQuestion}
                sx={{ flexShrink: 0, width: '15%' }}
              >
                Add Question
              </Button>
            </Stack>
            {/* <Editor value={editor} onChange={onValueChanges} onchan={setEditor} simple /> */}
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell>ID</TableCell> */}
                  <TableCell>Topic Or Question</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Answer Type</TableCell>
                  {/* <TableCell sx={{ width: '15%' }}>Yes/No/NA</TableCell> */}
                  <TableCell>Notes Included</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {checklistQuestions.map((question, index) => (
                  <TableRow>
                    <TableCell align="center">
                      <Select
                        variant="outlined"
                        name="que_topic"
                        value={question.que_topic}
                        onChange={(e) => handleChangeField(index, 'que_topic', e.target.value)}
                        sx={{
                          width: '100%',
                          borderRadius: '6px',
                        }}
                      >
                        <MenuItem value="">Select Topic or Question</MenuItem>
                        <MenuItem value="t">Topic</MenuItem>
                        <MenuItem value="q">Question</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        name="question"
                        value={question.question}
                        onChange={(e) => handleChangeField(index, 'question', e.target.value)}
                        sx={{
                          width: '100%',
                          borderRadius: '6px',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Select
                        variant="outlined"
                        name="na_incl"
                        value={question?.que_topic === 't' ? 'No' : question.na_incl}
                        disabled={question?.que_topic === 't'}
                        onChange={(e) => handleChangeField(index, 'na_incl', e.target.value)}
                        sx={{
                          width: '100%',

                          borderRadius: '6px',
                        }}
                      >
                        <MenuItem value="">Select Question Type</MenuItem>
                        <MenuItem value="No">Yes/No</MenuItem>
                        <MenuItem value="Yes">Yes/No/NA</MenuItem>
                      </Select>
                    </TableCell>

                    <TableCell align="center">
                      <Select
                        variant="outlined"
                        name="notes_incl"
                        value={question?.que_topic === 't' ? 'No' : question.notes_incl}
                        disabled={question?.que_topic === 't'}
                        onChange={(e) => handleChangeField(index, 'notes_incl', e.target.value)}
                        sx={{
                          width: '100%',

                          borderRadius: '6px',
                        }}
                      >
                        <MenuItem value="">Select Note Included</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        color="error"
                        startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                        onClick={() => handleRemoveQuestion(index)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isloading} type="submit">
            {edit && 'Update'} {!edit && 'Create'} Checklist
          </LoadingButton>

          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

interface JobStageChecklistModal {
  StageName: string;
  Single: string;
  Double: string;
  PrevStageName: string;
}

function JobStageChecklist({ open, onClose, editor, setEditor, edit = false, template }: Props) {
  const { user } = useAuthContext();
  // const [data, setData] = useState<Templates[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [jobStageChecklistData, setJobStageChecklisData] = useState<JobStageChecklistModal[]>([]);
  const distinctChecklistNames = Array.from(new Set(template.map((t) => t.checklist_name)));
  console.log('Checklist names: ', distinctChecklistNames);

  const getJobStageChecklist = useCallback(() => {
    axios
      .get(`/core/getStageChecklist`)
      .then((response) => {
        // const checklistMap = new Map<string, JobStageChecklistModal>();

        // response.data.forEach((item: any) => {
        //   if (!checklistMap.has(item.checklist_name)) {
        //     checklistMap.set(item.checklist_name, item);
        //   }
        // });

        // Convert the Map back to an array
        // const uniqueChecklistNames: JobStageChecklistModal[] = Array.from(checklistMap.values());
        // uniqueChecklistNames.sort((a, b) => {
        //   if (a.id < b.id) return 1;
        //   if (a.id > b.id) return -1;
        //   return 0;
        // });

        setJobStageChecklisData(response.data as JobStageChecklistModal[]);

        // console.log("Get checklist map data" , jobStageChecklistData);
        // setAllChecklists(response.data);
      })
      .catch((error) => {
        console.error('Error fetching get all templates:', error);
      });
  }, []);

  useEffect(() => {
    getJobStageChecklist();
  }, [getJobStageChecklist]);

  const CommentSchema = Yup.object().shape({
    Template: Yup.string(),
  });
  const defaultValues = {
    Template: '',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (template !== null) {
      // setValue('Template', template[0].checklist_name);
    } else {
      setValue('Template', '');
      setEditor('');
    }
  }, [setEditor, setValue, template]);
  const [checklistQuestions, setChecklistQuestions] = useState<ChecklistFields[]>([]);

  //   const handleAddChecklistQuestion = () => {
  //     setChecklistQuestions([
  //       ...checklistQuestions,
  //       {
  //         TopicOrQuestion: '',
  //         Question: '',
  //         AnswerType: '',
  //         Notes: '',
  //       } as ChecklistFields,
  //     ]);
  //   };

  const [isloading, setisLoading] = useState(false);

  const handleChangeStageChecklist = (
    index: number,
    field: keyof JobStageChecklistModal,
    value: any
  ) => {
    console.log('In single checklist change = ', value);
    if (value === '') {
      enqueueSnackbar('Stage name can not be empty.', { variant: 'error' });
    } else {
      const createChecklistQuestions = [...jobStageChecklistData];
      createChecklistQuestions[index] = {
        ...createChecklistQuestions[index],
        [field]: value,
      };
      setJobStageChecklisData(createChecklistQuestions);
    }
  };

  // const onValueChanges = (value: any) => {
  //   setEditor(value);
  // };
  function convertToUpdateStageChecklist(jobStageChecklists: any[], modifiedBy: any) {
    const updateStageChecklist: {
      StageName: any;
      StoreyName: string;
      ChecklistName: any;
      ModifiedBy: any;
    }[] = [];
    jobStageChecklists.forEach((item: any) => {
      if (item.Single) {
        updateStageChecklist.push({
          StageName: item.StageName,
          StoreyName: 'single',
          ChecklistName: item.Single,
          ModifiedBy: modifiedBy,
        });
      }
      if (item.Double) {
        updateStageChecklist.push({
          StageName: item.StageName,
          StoreyName: 'double',
          ChecklistName: item.Double,
          ModifiedBy: modifiedBy,
        });
      }
    });
    return updateStageChecklist;
  }

  const onSubmit = async (dat: FormValuesProps) => {
    try {
      await CommentSchema.validate(dat, { abortEarly: false });
      setisLoading(false);
      // const modifiedBy = 'user123';
      const updateStageChecklist = convertToUpdateStageChecklist(
        jobStageChecklistData,
        String(user?.ID)
      );
      const payload = {
        modified_by: String(user?.ID),
        checklist_mapping: updateStageChecklist,
      };
      console.log('Update job stage values = ', payload);
      axios
        .post(`/core/updateStageChecklist`, payload)
        .then((response) => {
          enqueueSnackbar(response.data);
          onClose();
          setisLoading(false);
          getJobStageChecklist();
        })
        .catch((error) => {
          enqueueSnackbar(error);
          setisLoading(false);
        });
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        Job Stage Checklist
        {/* {' '} */}
        {/* {edit && 'Edit'} {!edit && 'Add'} Checklist{' '} */}
      </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2} sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            {/* <RHFTextField name="Template" label="Checklist Name" /> */}
            {/* <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle1">Checklist Content</Typography>
              <Button
                size="small"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleAddChecklistQuestion}
                sx={{ flexShrink: 0, width: '15%' }}
              >
                Add Question
              </Button>
            </Stack> */}
            {/* <Editor value={editor} onChange={onValueChanges} onchan={setEditor} simple /> */}
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell>ID</TableCell> */}
                  <TableCell sx={{ width: '33%' }}>Stage Name</TableCell>
                  <TableCell sx={{ width: '33%' }}>Single Storey</TableCell>
                  <TableCell sx={{ width: '33%' }}>Double Storey</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {jobStageChecklistData?.map((question, index) => {
                  // Check if StageName is "Stage 3" or "Audit Stage 3" or "Stage 2" or "Audit Stage 2"
                  const isDisabled =
                    question.StageName === 'Stage 3' || question.StageName === 'Audit Stage 3' || question.StageName === 'Stage 2' || question.StageName === 'Audit Stage 2';

                  return (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <Select
                          variant="outlined"
                          name="stage_name"
                          value={question.StageName}
                          disabled
                          sx={{
                            width: '100%',
                            borderRadius: '6px',
                          }}
                        >
                          <MenuItem value="">Select Stage</MenuItem>
                          <MenuItem value="Stage 1">Stage 1</MenuItem>
                          <MenuItem value="Stage 2">Stage 2</MenuItem>
                          <MenuItem value="Stage 3">Stage 3</MenuItem>
                          <MenuItem value="Audit Stage 1">Audit Stage 1</MenuItem>
                          <MenuItem value="Audit Stage 2">Audit Stage 2</MenuItem>
                          <MenuItem value="Audit Stage 3">Audit Stage 3</MenuItem>
                        </Select>
                      </TableCell>

                      <TableCell align="center">
                        <Select
                          variant="outlined"
                          name="checklist_name"
                          value={question.Single}
                          onChange={(e) =>
                            handleChangeStageChecklist(index, 'Single', e.target.value)
                          }
                          sx={{
                            width: '100%',
                            borderRadius: '6px',
                          }}
                          disabled={isDisabled}
                        >
                          <MenuItem value="">Select Checklist</MenuItem>
                          {distinctChecklistNames.map((name, idx) => (
                            <MenuItem key={idx} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>

                      <TableCell align="center">
                        <Select
                          variant="outlined"
                          name="storey_name"
                          value={question.Double}
                          onChange={(e) =>
                            handleChangeStageChecklist(index, 'Double', e.target.value)
                          }
                          sx={{
                            width: '100%',
                            borderRadius: '6px',
                          }}
                          disabled={isDisabled}
                        >
                          <MenuItem value="">Select Checklist</MenuItem>
                          {distinctChecklistNames.map((name, idx) => (
                            <MenuItem key={idx} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isloading} type="submit">
            Update Stage Checklist
          </LoadingButton>

          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
