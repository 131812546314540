import {
  Button,
  Grid,
  TextField,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'src/components/snackbar';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Label from 'src/components/label/Label';
// import _mock from 'src/_mock/_mock';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
import { fData } from 'src/utils/formatNumber';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';

type WorkflowProfile = {
  UserProfilePic: any;
  UserUsername: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | any;
  UserCreatedAt: string;
  UserColor: string;
};
interface ViewJobDialogProp {
  workflow: WorkflowProfile;
  id: string;
  edit: boolean; 
  userColor: string;
}



export default function ProfileWorkflow({ workflow, id, edit,userColor }: ViewJobDialogProp) {
  console.log('Data = ', workflow);

  const [values, setValues] = useState<Date | null>(new Date());
  const [image, setImage] = useState<any>();
  const [colors, setColors] = useState<string[]>([]);
  const [selectedColor, setSelectedColor] = useState(userColor); // Default color if none is provided
  const [open, setOpen] = useState(false);
  const colorBoxRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountryCode, setSelectedCountryCode] = useState('+61');

  const CommentSchema = Yup.object().shape({
    UserFname: Yup.string().required('First Name is required'),
    UserLname: Yup.string().required('Last Name is required'),
    // UserEmail: Yup.string()
    //   .required('Email is required')
    //   .email('Email must be a valid email address'),
    // UserPhone: Yup.string().required('Phone Number is required'),
    UserEmail: Yup.string()
      // .required('Email is required')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        'Email must be a valid email address in the format name@example.com'
      ),
    UserPhone: Yup.string()
      .required('Phone Number is required. Minimum 8 Maximum 10')
      .min(8)
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return false;
        return /^(\+61-){0,1}\d+$/.test(value);
        // return /^(\+61-|\+1-){0,1}\d+$/.test(value);
      }),

    // UserGender: Yup.string().required('Gender is required'),
    // UserState: Yup.string().required('State is required'),
    // UserZipcode: Yup.string().required('ZipCode is required'),
    UserStatus: Yup.string().required('Status is required'),
    // UserStreetAddr: Yup.string().required('Address is required'),
    UserCountry: Yup.string().required('Country is required'),
    UserProfilePic: Yup.string(),
  });

  const handleColorChange = (event: any, newColor: any) => {
    if (newColor !== null) {
      setSelectedColor(newColor);
      setValue('UserColor', newColor);
      setOpen(false);
    }
  };
 
  const handleClearSelection = () => {
    setSelectedColor("");
    setValue("UserColor", "");
    setOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        colorBoxRef.current &&
        !colorBoxRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await axios.get('/core/getColorValueConfig', {
          params: {
            topic: 'workflowcolor',
            keyword_name: 'color_code'
          }
        });
        
        if (response.data && response.data.length > 0) {
          const colorArray = response.data[0].split(',').map((color: string) => color.trim());
          setColors(colorArray);
        } else {
          console.warn('No colors received from API');
        }
      } catch (error) {
        console.error('Error fetching colors:', error); 
      }
    };
  
    fetchColors();
  }, []);


  const defaultValues = {
    UserUsername: '',
    UserFname: '',
    UserLname: '',
    UserEmail: '',
    UserPhone: '',
    UserGender: '',
    UserState: '',
    UserZipcode: '',
    UserStatus: '',
    UserStreetAddr: '',
    UserCountry: 'Australia',
    UserProfilePic: '',
    UserColor: '#ffffff',
  };

  const methods = useForm<WorkflowProfile>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    setValue,
    // reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    console.log('Workflow data:', workflow);
    if (workflow) {
      setValue('UserPhone', workflow.UserPhone);
      if (workflow.UserPhone.startsWith('+61')) {
        setSelectedCountryCode('+61');
        // } else if (workflow.UserPhone.startsWith('+1')) {
        //   setSelectedCountryCode('+1');
      } else {
        setSelectedCountryCode(' ');
      }
      // workflow.UserPhone = workflow.UserPhone.replace('+61-', '');
      // workflow.UserPhone = workflow.UserPhone.replace('+1-', '');
      const indexofHyphen = workflow.UserPhone.indexOf('-');
      if (indexofHyphen !== -1) {
        setValue('UserPhone', workflow.UserPhone.substring(indexofHyphen + 1));
      } else {
        setValue('UserPhone', workflow.UserPhone);
      }
      setValue('UserUsername', workflow.UserUsername);
      setValue('UserFname', workflow.UserFname);
      setValue('UserLname', workflow.UserLname);
      setValue('UserEmail', workflow.UserEmail);

      if (workflow.UserGender === 'M') {
        setValue('UserGender', 'Male');
      } else if (workflow.UserGender === 'F') {
        setValue('UserGender', 'Female');
      } else {
        setValue('UserGender', '');
      }

      setValue('UserState', workflow.UserState);
      setValue('UserZipcode', workflow.UserZipcode);
      setValue('UserStatus', workflow.UserStatus || 'active');
      setValue('UserStreetAddr', workflow.UserStreetAddr);
      setValue('UserCountry', workflow.UserCountry);
    }
    if (workflow.UserProfilePic) {
      axios
        .get(`/core/getAssessorProfilePic?loc=${workflow.UserProfilePic}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const blob = response.data;
          const objectUrl = URL.createObjectURL(blob);
          setValue('UserProfilePic', objectUrl, { shouldValidate: true });
        })
        .catch((error) => {
          console.error('Error fetching workflow:', error);
        });
    }
  }, [workflow, setValue]);

  console.log('Post set Phone number : ', workflow.UserPhone);
  console.log('Post Set Country Code : ', selectedCountryCode);

  const goToWorkflowList = () => {
    navigate('/workflowUserList');
  };

  const onSubmit = async (dat: WorkflowProfile) => {
    try {
      if (dat.UserPhone.trim() !== '') {
        dat.UserPhone = dat.UserPhone.replace('+61-', '');
        if (selectedCountryCode === '+61') {
          if (!dat.UserPhone.startsWith('+61')) {
            dat.UserPhone = `+61-${dat.UserPhone}`;
          }
        }
      }
  
      await CommentSchema.validate(dat, { abortEarly: false });
      const formDataObj = new FormData();
      formDataObj.append('file', image);
      formDataObj.append('workflow', JSON.stringify(dat));
  
      axios
  .post(`/core/updateWorkflow?id=${id}`, formDataObj)
  .then((response) => {
    enqueueSnackbar(response.data, { variant: 'success' });
  })
  .catch((error) => {
    console.log("Full error object:", error);

    let errorMessage = 'An unexpected error occurred.';

    // Check if the error message exists directly
    if (error.error && typeof error.error === 'string') {
      if (error.error.includes("color already assigned to another workflow user")) {
        errorMessage = "This color is already assigned to another workflow user.Please use another color.";
      } else {
        errorMessage = error.error;
      }
    } else if (error.response && error.response.data) {
      errorMessage = error.response.data.error || error.response.data;
    } else {
      errorMessage = error.message || "An unexpected error occurred.";
    }

    enqueueSnackbar(errorMessage, { variant: 'error' });
  });

    } catch (error) {
      console.error(error);
    }
  };
  

  // const onSubmit = async (dat: WorkflowProfile) => {
  //   try {
  //     if (dat.UserPhone.trim() !== '') {
  //       dat.UserPhone = dat.UserPhone.replace('+61-', '');
  //       // dat.UserPhone = dat.UserPhone.replace('+1-', '');
  //       if (selectedCountryCode === '+61') {
  //         if (!dat.UserPhone.startsWith('+61')) {
  //           dat.UserPhone = `+61-${dat.UserPhone}`;
  //         }
  //         // } else if (selectedCountryCode === '+1') {
  //         //   if (!dat.UserPhone.startsWith('+1')) {
  //         //     dat.UserPhone = `+1-${dat.UserPhone}`;
  //         //   }
  //       }
  //     }
  //     // setValue('UserPhone', dat.UserPhone);

  //     console.log('Workflow data = ', dat);
  //     await CommentSchema.validate(dat, { abortEarly: false });
  //     const formDataObj = new FormData();
  //     formDataObj.append('file', image);
  //     formDataObj.append('workflow', JSON.stringify(dat));
  //     axios
  //       .post(`/core/updateWorkflow?id=${id}`, formDataObj)
  //       .then((response) => {
  //         enqueueSnackbar(response.data, { variant: 'success' });
  //       })
  //       .catch((error) => {
  //         enqueueSnackbar(error, { variant: 'error' });
  //       });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setImage(file);
        setValue('UserProfilePic', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  return (
    <>
      {/* <Container> */}
      <Grid container display="flow">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} md={12} xl={12}>
            <Grid item xs={12} md={4}>
              {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
              <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <RHFUploadAvatar
                  name="UserProfilePic"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  disabled={!edit}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 4,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />

                <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                  Role : Workflow
                </Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                    label="Created Date"
                    disabled
                    value={workflow?.UserCreatedAt}
                    onChange={setValues}
                  />
                </LocalizationProvider>
              </Stack>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} md={8} sx={{}}>
              {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="UserUsername" label="User Name*" disabled />
                <RHFTextField name="UserFname" label="First Name*" disabled={!edit} />
                <RHFTextField name="UserLname" label="Last Name*" disabled={!edit} />
                <RHFTextField name="UserEmail" label="Email Address*" disabled={!edit} />
                {/* <RHFTextField name="UserPhone" label="Phone Number" disabled={edit} /> */}
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  {/* <RHFTextField
                    name="CountryCode"
                    label="Country Code"
                    // disabled={!edit}
                    sx={{ flex: 1 }}
                    InputProps={{
                      inputProps: { maxLength: 4 },
                      value: '+61',
                      readOnly: true, // non-editable
                    }}
                  /> */}
                  <FormControl>
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      name="CountryCode"
                      label="Country Code"
                      value={selectedCountryCode}
                      // onChange={(event) => setSelectedCountryCode(event.target.value)}
                      onChange={(event: SelectChangeEvent) => {
                        const selectcountrycode = event.target.value as string;

                        if (selectcountrycode === '+61') {
                          methods.setValue('UserCountry', 'Australia');
                          // } else if (selectcountrycode === '+1') {
                          //   methods.setValue('UserCountry', 'United States');
                        }

                        setSelectedCountryCode(event.target.value);
                      }}
                      disabled={!edit}
                    >
                      <MenuItem value="+61">+61</MenuItem>
                      {/* <MenuItem value="+1">+1</MenuItem> */}
                    </Select>
                  </FormControl>

                  {/* <RHFTextField
                    name="UserPhone"
                    label="Phone Number**"
                    // disabled={!edit}
                    sx={{ flex: 5 }}
                    InputProps={{ inputProps: { maxLength: 10 } }}
                  /> */}
                  <RHFTextField
                    name="UserPhone"
                    label="Phone Number*"
                    disabled={!edit}
                    sx={{ flex: 5 }}
                    InputProps={{ inputProps: { maxLength: 10 } }}
                  />
                </Stack>

                <RHFSelect name="UserGender" label="Gender" disabled={!edit}>
                  {/* <MenuItem value="">None</MenuItem> */}
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </RHFSelect>

                <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField
                    name="UserStreetAddr"
                    disabled={!edit}
                    multiline
                    rows={3}
                    label="Address"
                  />
                </Stack>
                {/* <RHFTextField name="UserState" disabled={edit} label="State/Region" /> */}
                <RHFSelect
                  name="UserState"
                  label="State/Region"
                  placeholder="State"
                  disabled={!edit}
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  <MenuItem value="NSW - New South Wales">NSW - New South Wales</MenuItem>
                  <MenuItem value="VIC - Victoria">VIC - Victoria</MenuItem>
                  <MenuItem value="QLD - Queensland">QLD - Queensland</MenuItem>
                  <MenuItem value="WA  - Western Australia">WA - Western Australia</MenuItem>
                  <MenuItem value="SA  - South Australia">SA - South Australia</MenuItem>
                  <MenuItem value="TAS - Tasmania">TAS - Tasmania</MenuItem>
                </RHFSelect>
                {/* <RHFSelect
                  native
                  name="UserCountry"
                  label="Country*"
                  disabled={edit}
                  placeholder="Country"
                >
                  <option key="Australia" value="Australia">
                    Australia
                  </option>
                  <option key="United States" value="United States">
                    United States
                  </option>
                </RHFSelect> */}
                <FormControl>
                  <InputLabel>Country*</InputLabel>
                  <Select
                    name="UserCountry"
                    label="Country*"
                    disabled={!edit}
                    placeholder="Country"
                    value={methods.watch('UserCountry')} // Get the selected country from form data
                    onChange={(event: SelectChangeEvent) => {
                      const selectedCountry = event.target.value as string;

                      if (selectedCountry === 'Australia') {
                        setSelectedCountryCode('+61');
                        // } else if (selectedCountry === 'United States') {
                        //   setSelectedCountryCode('+1');
                      }

                      // Call the form control's onChange event to update the selected country
                      methods.setValue('UserCountry', selectedCountry);
                    }}
                  >
                    <MenuItem value="Australia">Australia</MenuItem>
                    {/* <MenuItem value="United States">United States</MenuItem> */}
                  </Select>
                </FormControl>
                {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                <RHFTextField name="UserZipcode" disabled={!edit} label="Postal Code" />
                <RHFSelect
                  native
                  name="UserStatus"
                  label="Status*"
                  disabled={!edit}
                  placeholder="Status"
                >
                  <option key="active" value="active">
                    Active
                  </option>
                  <option key="inactive" value="inactive">
                    Inactive
                  </option>
                  {/* <option key="deleted" value="deleted">
                    deleted
                  </option> */}
                </RHFSelect>
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{ position: "relative" }}
                  ref={colorBoxRef}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body2">Pick a Color:</Typography>
                    <Button
                      variant="outlined"
                      onClick={() => setOpen(!open)}
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      {selectedColor && (
                        <Box
                          sx={{
                            width: 34,
                            height: 24,
                            backgroundColor: selectedColor,
                            border: "1px solid #ccc",
                            borderRadius: "4px"
                          }}
                        />
                      )}
                      Select Color
                    </Button>
                  </Stack>
                  {open && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "100%",
                        left: 0,
                        zIndex: 5,
                        backgroundColor: "white",
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        border: "1px solid #e0e0e0",
                        borderRadius: "4px",
                        padding: "8px",
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>Select Color</Typography>
                      <ToggleButtonGroup
                        value={selectedColor}
                        exclusive
                        onChange={handleColorChange}
                        aria-label="color selection"
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(4, 1fr)",
                          gap: 2,
                          padding: "4px",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        {colors.map((color) => (
                          <ToggleButton
                            key={color}
                            value={color}
                            sx={{
                              width: 40,
                              height: 40,
                              backgroundColor: color,
                              border: selectedColor === color ? "2px solid black" : "none",
                              "&:hover": { opacity: 0.7 }
                            }}
                            aria-label={color}
                          />
                        ))}
                      </ToggleButtonGroup>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <Button
                          size="small"
                          variant="outlined" 
                          onClick={handleClearSelection}
                          sx={{
                            color: 'black', 
                            fontWeight: 1000,
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                          }}
                        >
                          No Color 
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Stack>
              </Box>
              {/* </Card> */}
              <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                <Grid item>&nbsp;</Grid>
                {/* <Button variant="contained" onClick={goToWorkflowList} color="primary">
                  Back
                </Button> */}

                {edit && (
                  <Grid item>
                    <Button variant="contained" type="submit" color="primary">
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      onClick={goToWorkflowList}
                      color="secondary"
                      sx={{ marginLeft: '10px' }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </>
  );
}
